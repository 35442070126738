import api from "../utils/Api";
export async function UpdateDoctorStatus(data, id) {
  try {
    const response = await api.post(`/admin/doctor/update-status/${id}`, data);
    if (response) return response;
    else throw new Error("Could not update doctor");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
