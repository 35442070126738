import { Backdrop, CircularProgress, Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import DoctorModal from "./DoctorModal";
import { useOutletContext } from "react-router-dom";

const DoctorList = () => {
  const [isAbha, setIsAbha] = useState(undefined);
  const [active, setActive] = useState(true);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [doctorData, setDoctorData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const [isEditDoctor, setIsEditDoctor] = useState(false);
  const { searchText } = useOutletContext();
  const tableHeader = [
    "Name",
    "Registration no",
    "Phone no",
    "Email ID",
    "Joining Date",
    "Subscription",
  ];
  const tableHeader2 = [
    "Name",
    "Hospital Name",
    "Phone",
    "Email ID",
    "Type",
    "Status",
  ];
  const keysName = [
    "name",
    "registrationNumber",
    "phone",
    "email",
    "createdAt",
    "doctorStatus",
  ];
  const keysName2 = [
    "name",
    "registrationNumber",
    "hospitalName",
    "phone",
    "email",
    "role",
    "doctorStatus",
  ];

  const DoctorList = (e) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        ...(e &&
          e.target.value && {
            search: e.target.value,
          }),
        limit: rowsPerPage,
        page: pageNumber + 1,
        doctorRole: isAbha,
      };

      api
        .post(`/admin/doctors-list?isActive=${active}`, PayLoadDoctorList)
        .then((response) => {
          const list = response?.data?.data?.doctorList?.map((x) => {
            return {
              ...x,
              role: x?.role?.type || "-",
            };
          });
          setDoctorData(list || []);
          setList(list || []);
          setCount(response?.data?.data?.totalCount);
          setBackDropOpen(false);
        })
        .catch((err) => {
          setBackDropOpen(false);
          if (err?.response?.data?.message) {
            toast.error(`Error :${err?.response?.data?.message}`);
          } else {
            toast.error("Doctor Not Found");
          }
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Doctor list error");
    }
  };
  useEffect(() => {
    const data = list?.filter((x) =>
      x?.name?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    setDoctorData(data);
  }, [searchText, list]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPageNumber(0);
  };

  const handleModal = (isEdit, e = {}) => {
    setData(e);
    setIsEditDoctor(isEdit);
    setOpenModal(true);
  };

  const handleModalClose = (buttonText = "save") => {
    setData({});
    if (buttonText !== "close") {
      DoctorList();
    }
    setOpenModal(false);
  };

  useEffect(() => {
    DoctorList();
  }, [rowsPerPage, pageNumber, isAbha, active]);

  useEffect(() => {
    DoctorList();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          fontWeight: "500",
          fontSize: "1.1rem",
          margin: "1rem 0",
        }}
      >
        List of all doctors
      </Box>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => setActive(true)}
            sx={{
              borderBottom: active && "2px solid #194AF5",
              color: active ? "#194AF5" : "#7D8DC4",
              width: "max-content",
              padding: "0.5rem 1rem",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            Active
          </Box>
          <Box
            onClick={() => setActive(false)}
            sx={{
              borderBottom: !active && "2px solid #194AF5",
              color: !active ? "#194AF5" : "#7D8DC4",
              width: "max-content",
              padding: "0.5rem 1rem",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            Inactive
          </Box>
        </Box>
      </Stack>
      <Listtable
        tableheader={isAbha === "RECEPTION" ? tableHeader2 : tableHeader}
        tablebody={doctorData}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={count}
        dataKeyName={isAbha === "RECEPTION" ? keysName2 : keysName}
        editHandle={(e) => handleModal(true, e)}
        tag="doctors"
      />
      {openModal && (
        <DoctorModal
          open={openModal}
          onClose={handleModalClose}
          doctorData={data}
          isEditDoctor={isEditDoctor}
        />
      )}
    </>
  );
};

export default DoctorList;
