import {
    Grid,
    FormControlLabel,
    Checkbox,
    FormGroup,
    TextField,
    FormControl,
    RadioGroup,
    Radio,
} from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import api from "../../utils/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DateTimePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";


const AvailabilityModel = ({ open, onClose, doctorData, hospitalId, availabilityData, unavailabilityData, isEdit, editFormName }) => {
    const { t } = useTranslation();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [maxAppointments, setMaxAppointments] = useState("");

    const [week, setWeek] = useState([{ day: 'MONDAY', isChecked: false },
    { day: 'TUESDAY', isChecked: false },
    { day: 'WEDNESDAY', isChecked: false },
    { day: 'THURSDAY', isChecked: false },
    { day: 'FRIDAY', isChecked: false },
    { day: 'SATURDAY', isChecked: false },
    { day: 'SUNDAY', isChecked: false }])

    const [formName, setFormName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [doctorId, setDoctorId] = useState("");


    useEffect(() => {
        if (isEdit) {
            setFormName(editFormName);
            if (editFormName === 'availability') {
                setStartTime(availabilityData?.startTime || "")
                setEndTime(availabilityData?.endTime || "")
                setMaxAppointments(availabilityData?.maxAppointments || "")
                setDoctorId(availabilityData.doctorId)

                let weekData = week?.map(x => {
                    if (availabilityData?.days?.indexOf(x.day) !== -1) {
                        x.isChecked = true
                    }
                    return x;
                })
                setWeek(weekData);
            } else {
                setStartDate(dayjs(unavailabilityData?.startDate))
                setEndDate(dayjs(unavailabilityData?.endDate))
                setDoctorId(unavailabilityData.doctorId)
            }
        } else {
            setFormName('availability');
            setDoctorId(doctorData._id)
        }
    }, [])

    const handleWeek = (e, day) => {
        let daysArray = week.map(x => {
            if (x.day === day) {
                x.isChecked = !(x.isChecked)
            }
            return x;
        })
        setWeek(daysArray)

    }

    const handleCreateAvailability = (e) => {
        e.preventDefault();
        let days = week.filter(x => x.isChecked).map(x => {
            return x.day;
        })

        if (days.length) {
            let payload = {
                days,
                startTime,
                endTime,
                ...(isEdit && { slotId: availabilityData._id }),
                maxAppointments,
                clinicId: hospitalId,
                doctorId,
            }

            api.post(`/reg/add-doctor-hospital-slot`, payload)
                .then((res) => {
                    onClose();
                    toast.success(res && res?.message);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.error);
                });
        } else {
            toast.error("Please select at least 1 day");
        }
    }

    const handleCreateUnavailability = (e) => {
        e.preventDefault();

        // if (days.length) {
        let payload = {
            clinicId: hospitalId,
            doctorId,
            startDate,
            endDate,
            ...(isEdit && { slotId: unavailabilityData._id }),
        }

        api.post(`/reg/add-doctor-hospital-unavailabilities`, payload)
            .then((res) => {
                onClose();
                toast.success(res && res?.message);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error);
            });
        // } else {
        //     toast.error("Please select at least 1 day");
        // }
    }


    return (
        <div>
            <Modal
                open={open}
                onClose={() => onClose('close')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        justifyContent="left"
                        sx={{ mb: 3 }}>
                        {!isEdit && <Grid item>
                            <FormControl>
                                <RadioGroup sx={{ flexDirection: "row" }}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={formName}
                                    name="radio-buttons-group"
                                    onChange={(e) => setFormName(e.target.value)}
                                >
                                    <FormControlLabel value="availability" control={<Radio />} label="Create Availability" />
                                    <FormControlLabel value="unavailability" control={<Radio />} label="Create Unavailability" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>}
                    </Grid>
                    {formName === "availability" ?
                        <form onSubmit={handleCreateAvailability}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item sm={12} lg={12} md={12}>
                                    <FormGroup sx={{ flexDirection: "row" }} >
                                        {week?.map(({ day, isChecked }) => (

                                            <FormControlLabel control={<Checkbox onChange={(e) => handleWeek(e, day)} checked={isChecked} />} label={day} />
                                        ))}
                                    </FormGroup>


                                </Grid>
                                <Grid item sm={12} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            slotProps={{
                                                textField: {
                                                    required: true,
                                                },
                                            }}
                                            label="From"
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock,
                                            }}


                                            value={new Date(moment(startTime, "HH:mm"))}
                                            onChange={(time) =>
                                                setStartTime(moment(time).format("HH:mm"))
                                            }
                                        />

                                    </LocalizationProvider>
                                </Grid>
                                <Grid item sm={12} lg={4} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            slotProps={{
                                                textField: {
                                                    required: true,
                                                },
                                            }}
                                            label="To"
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock,
                                            }}

                                            ampm
                                            value={new Date(moment(endTime, "HH:mm"))}
                                            onChange={(time) =>
                                                setEndTime(moment(time).format("HH:mm"))
                                            }
                                        />

                                    </LocalizationProvider>
                                </Grid>
                                <Grid item sm={12} lg={4} md={4}>
                                    <TextField
                                        fullWidth
                                        type={"text"}
                                        value={maxAppointments}
                                        label="Max Appointments"
                                        name="maxAppointments"
                                        onChange={(e) => setMaxAppointments(e.target.value)}
                                        size="small"
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    lg={12}
                                    md={12}
                                    display="flex"
                                    justifyContent="right">
                                    <Button variant="contained" type="submit" >
                                        {isEdit ? t('UPDATE') : t('CREATE')}
                                    </Button>
                                    <Button
                                        sx={{ marginLeft: "5px" }}
                                        variant="contained"
                                        onClick={() => onClose('close')}>
                                        {t('CANCEL')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form> :

                        <form onSubmit={handleCreateUnavailability}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item sm={12} lg={12} md={12}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                            <DateTimePicker
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(newValue) => setStartDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                </Grid>
                                <Grid item sm={12} lg={12} md={12}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                            <DateTimePicker
                                                label="End Date"
                                                value={endDate}
                                                onChange={(newValue) => setEndDate(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    lg={12}
                                    md={12}
                                    display="flex"
                                    justifyContent="right">
                                    <Button variant="contained" type="submit" >
                                        {isEdit ? t('UPDATE') : t('CREATE')}
                                    </Button>
                                    <Button
                                        sx={{ marginLeft: "5px" }}
                                        variant="contained"
                                        onClick={() => onClose('close')}>
                                        {t('CANCEL')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>}
                </Box>
            </Modal >
        </div >
    );
};

export default AvailabilityModel;
