import {
  Autocomplete,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormControl,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import api from "../utils/Api";
import { toast } from "react-toastify";

import DatePicker from "./DatePicker";
import moment from "moment";
import ConfirmModal from "./ConfirmModal/ConfirmModal";

const ModalScreen = ({ open, onClose, data, type }) => {
  console.log("type", type);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };
  const [drugTypes, setDrugTypes] = useState([]);
  const [allSpecialities, setAllSpecialities] = useState([]);
  const [association, setAssociation] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [_id, setID] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [educationName, setEducationName] = useState("");
  const [doctorEmail, setDoctorEmail] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [doctorExperience, setDoctorExperience] = useState("");
  const [doctorEducation, setDoctorEducation] = useState("");
  const [specialities, setSpecialities] = useState([]);
  const [doctorStatus, setDoctorStatus] = useState("");
  const [speciazationName, setSpeciazationName] = useState("");
  const [associationName, setAssociationName] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientPhone, setPatientPhone] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientABHA, setPatientABHA] = useState("");
  const [patientDOB, setPatientDOB] = useState(
    moment(new Date().toDateString())
  );

  const [companyName, setCompanyName] = useState("");
  const [medicineName, setMedicineName] = useState("");
  const [testName, setTestName] = useState("");

  const [genericName, setGenericName] = useState("");
  const [drugType, setDrugType] = useState("");
  const [doctorSpeciality, setdoctorSpeciality] = useState();
  const [drugStatus, setDrugStatus] = useState("");
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitDrugDetails = () => {
    try {
      const drugPayload = {
        company: companyName,
        speciality: doctorSpeciality?._id,
        drugName: medicineName,
        genericName,
        drugType,
        // status: drugStatus,
      };
      if (_id) {
        api
          .patch(`/drug/${_id}`, drugPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Drug Updated Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            toast.error("Cannot add a drug with duplicate name");
          });
      } else {
        api
          .post(`/drug`, drugPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Drug Created Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            onClose("close");
            toast.error("Cannot add a drug with duplicate name");
          });
      }
    } catch (err) {
      setIsApiLoading(false);
      onClose("close");
      toast.error(`Failed to ${_id ? "update" : "add"} drug !`);
    }
  };
  const submitTestDetails = () => {
    try {
      const testPayload = {
        // companyName,
        name: testName,
        // status: drugStatus,
      };
      if (_id) {
        api
          .patch(`/test/${_id}`, testPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Test Updated Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            toast.error("Error " + err);
          });
      } else {
        api
          .post(`/test`, testPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Test Created Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            onClose("close");
            toast.error("Error " + err);
          });
      }
    } catch (err) {
      setIsApiLoading(false);
      onClose("close");
      toast.error(`Failed to ${_id ? "update" : "add"} drug !`);
    }
  };
  // console.log(data);
  const getSpecialization = () => {
    setIsLoading(true);
    api
      .get(`/specialization`)
      .then((res) => {
        // setIsApiLoading(false);
        // onClose();
        // toast.success("Drug Updated Succesfully");
        if (res) {
          setAllSpecialities(res?.data?.data?.Specialization);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        // setIsApiLoading(false);
        toast.error("Error " + err);
      });
  };
  const getAssociation = () => {
    setIsLoading(true);
    api.get(`/association`).then((res) => {
      setAssociation(res?.data?.data?.Association);
      setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
  };

  const getDrugTypes = () => {
    api
      .get(`/drug_type`)
      .then((res) => {
        // setIsApiLoading(false);
        // onClose();
        // toast.success("Drug Updated Succesfully");
        // console.log(res?.data?.data?.DrugTypes);
        setDrugTypes(res?.data?.data?.DrugTypes);
      })
      .catch((err) => {
        // setIsApiLoading(false);
        // toast.error("Error " + err);
      });
  };
  const deleteDrug = () => {
    api
      .post(`/drug/${_id}`)
      .then((res) => {
        // setIsApiLoading(false);
        // onClose();
        // toast.success("Drug Updated Succesfully");
        console.log(res?.data);
        // setDrugTypes(res?.data?.data?.DrugTypes);
      })
      .catch((err) => {
        // setIsApiLoading(false);
        toast.error("Error " + err);
      });
  };
  const deleteEducation = () => {
    api
      .delete(`/education/${_id}`)
      .then((res) => {
        setIsApiLoading(false);
        onClose();
        toast.success("Education Deleted Successfully");
      })
      .catch((err) => {
        setIsApiLoading(false);
        toast.error("Error " + err);
      });
  };
  const handleSubmitDoctor = () => {
    try {
      const PayloadDoctor = {
        name: doctorName,
        experience: doctorExperience,
        education: doctorEducation,
        specialities: specialities,
        doctorStatus,
      };
      api
        .post(`/admin/doctor/update/${_id}`, PayloadDoctor)
        .then((res) => {
          onClose();
          toast.success("Doctor Updated Succesfully");
        })
        .catch((err) => {
          toast.error("Error " + err);
        });
    } catch (error) {
      toast.error(`Error ${error}`);
    }
  };
  const handleSubmitSpecialization = () => {
    try {
      const specializationPayload = {
        // companyName,
        name: speciazationName,
        // status: drugStatus,
      };
      if (_id) {
        api
          .patch(`/specialization/${_id}`, specializationPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Specialization Updated Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            toast.error("Cannot add a speciality with duplicate name");
          });
      } else {
        api
          .post(`/specialization`, specializationPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Specialization Created Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            onClose("close");
            toast.error("Cannot add a speciality with duplicate name");
          });
      }
    } catch (err) {
      setIsApiLoading(false);
      onClose("close");
      toast.error(`Failed to ${_id ? "update" : "add"} specialization!`);
    }
  };

  const handleSubmitAssociation = () => {
    try {
      const associationPayload = {
        // companyName,
        name: associationName,
        // status: drugStatus,
      };
      if (_id) {
        api
          .patch(`/association/${_id}`, associationPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Association Updated Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            toast.error("Cannot add a association with duplicate name");
          });
      } else {
        api
          .post(`/association`, associationPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Association Created Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            onClose("close");
            toast.error("Cannot add a association with duplicate name");
          });
      }
    } catch (err) {
      setIsApiLoading(false);
      onClose("close");
      toast.error(`Failed to ${_id ? "update" : "add"} specialization!`);
    }
  };


  const handleSubmitEducation = () => {
    try {
      const educationPayload = {
        // companyName,
        name: educationName,
        // status: drugStatus,
      };
      if (_id) {
        api
          .patch(`/education/${_id}`, educationPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Education Updated Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            toast.error("Cannot add a Education with duplicate name");
          });
      } else {
        api
          .post(`/education`, educationPayload)
          .then(() => {
            setIsApiLoading(false);
            onClose();
            toast.success("Education Created Succesfully");
          })
          .catch((err) => {
            setIsApiLoading(false);
            onClose("close");
            toast.error("Cannot add a Education with duplicate name");
          });
      }
    } catch (err) {
      setIsApiLoading(false);
      onClose("close");
      toast.error(`Failed to ${_id ? "update" : "add"} Education!`);
    }
  };
  const enableBtn = () => {
    setDisabled(false);
  };
  useEffect(() => {
    // console.log(data);
    if (Object.entries(data).length > 0) {
      if (type === "doctors") {
        setDoctorName(data?.name);
        setDoctorEmail(data?.email);
        setDoctorExperience(data?.experience);
        setDoctorEducation(data?.education);
        setDoctorPhone(data?.phone);
        setSpecialities([...data?.specialities]);
        setDoctorStatus(data?.doctorStatus);
        setID(data?._id);
      } else if (type === "patients") {
        setPatientName(data?.name);
        setPatientEmail(data?.email);
        setPatientDOB(data?.dateOfBirth);
        setPatientGender(data?.gender);
        setPatientPhone(data?.phone);
        setPatientABHA(data?.healthId[0]);
      } else if (type === "drug") {
        setCompanyName(data?.company || "");
        setMedicineName(data?.drugName || "");
        setID(data?._id || "");
        setGenericName(data?.genericName || "");
        setDrugType(data?.drugType._id);
        setdoctorSpeciality(data?.speciality);
        setDrugStatus(data?.status || "");
      } else if (type === "test") {
        setID(data?._id || "");
        setTestName(data?.name || undefined);
      } else if (type === "Specialization") {
        setID(data?._id || "");
        setSpeciazationName(data?.name || "");
      } else if (type === "Education") {
        setID(data?._id || "");
        setEducationName(data?.name || "");
      } else if (type === "Association") {
        setID(data?._id || "");
        setAssociationName(data?.name || "");
      }
    }
    getSpecialization();
    getAssociation();
    getDrugTypes();
    console.log("edu", data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Modal
        open={open}
        onClose={() => onClose("close")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="left"
            sx={{ mb: 3 }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h5" component="h5" align="center">
                {type === "doctors" && "Edit Doctor"}
                {_id && type === "drug" && "Edit Drug"}
                {type === "drug" && !_id && "Add Drug"}
                {type === "test" && !_id && "Add Test"}
                {type === "test" && _id && "Edit Test"}
                {type === "Specialization" && !_id && "Add Specialization"}
                {type === "Specialization" && _id && "Edit Specialization"}
                {type === "Education" && !_id && "Add Education"}
                {type === "Education" && _id && "Edit Education"}
                {type === "Association" && !_id && "Add Association"}
                {type === "Association" && _id && "Edit Association"}
              </Typography>
              <Box
                sx={{
                  display:
                    _id && type !== "test" && type !== "Specialization" && type !== "Association"
                      ? "flex"
                      : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px",
                  height: "30px",
                  bgcolor: "#FFE6F0",
                  borderRadius: "8px",
                  cursor: data.docCount > 0 ? "default" : "pointer",
                }}
              >
                {data.docCount > 0 ? (
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <rect width="32" height="32" rx="8" fill="#DDDDDD" />
                    <path
                      d="M14 16V21"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 16V21"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 11H24"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 14V22C10 23.6569 11.3432 25 13 25H19C20.6569 25 22 23.6569 22 22V14"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13 9C13 7.89543 13.8954 7 15 7H17C18.1046 7 19 7.89543 19 9V11H13V9Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                    width={25}
                    height={25}
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M10.4941 12.5V17.5"
                      stroke="#F51934"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.4941 12.5V17.5"
                      stroke="#F51934"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.49414 7.5H20.4941"
                      stroke="#F51934"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.49414 10.5V18.5C6.49414 20.1569 7.83729 21.5 9.49414 21.5H15.4941C17.151 21.5 18.4941 20.1569 18.4941 18.5V10.5"
                      stroke="#F51934"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.49414 5.5C9.49414 4.39543 10.3896 3.5 11.4941 3.5H13.4941C14.5987 3.5 15.4941 4.39543 15.4941 5.5V7.5H9.49414V5.5Z"
                      stroke="#F51934"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Grid>
          {type === "doctors" && ( //MARK: DOCTOR
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  value={doctorName}
                  label="Name"
                  name="name"
                  onChange={(e) => {
                    enableBtn();
                    setDoctorName(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Email"
                  name="email"
                  disabled
                  value={doctorEmail}
                  onChange={(e) => {
                    enableBtn();
                    setDoctorEmail(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Phone"
                  name="email"
                  disabled
                  onChange={(e) => {
                    enableBtn();
                    setDoctorPhone(e.target.value);
                  }}
                  value={doctorPhone}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Experience"
                  size="small"
                  name="experience"
                  value={doctorExperience}
                  onChange={(e) => {
                    enableBtn();
                    setDoctorExperience(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Education"
                  size="small"
                  name="education"
                  value={doctorEducation}
                  onChange={(e) => {
                    enableBtn();
                    setDoctorEducation(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <Autocomplete
                  options={allSpecialities}
                  multiple
                  value={specialities}
                  onChange={(e, newValue) => {
                    enableBtn();
                    setSpecialities([...newValue]);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="filled"
                        label={`${option.name}`}
                        {...getTagProps(option)}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="specialities"
                      label="Specialization"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label-doctor">
                    Doctor Status
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-select-small-label-doctor"
                    id="demo-select-small-deoctor"
                    value={doctorStatus}
                    label="Doctor Status"
                    onChange={(e) => {
                      enableBtn();
                      setDoctorStatus(e.target.value);
                    }}
                  >
                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                    <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid
                item
                sm={12}
                lg={12}
                md={12}
                display="flex"
                justifyContent="right"
              >
                <Button variant="contained" onClick={handleSubmitDoctor}>
                  Submit
                </Button>
                <Button
                  sx={{ marginLeft: "5px" }}
                  variant="contained"
                  onClick={() => onClose("close")}
                >
                  Cancel
                </Button>
              </Grid> */}
            </Grid>
          )}
          {type === "patients" && (
            <Grid container spacing={3} justifyContent="center">
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  value={patientName}
                  onChange={(e) => {
                    enableBtn();
                    setPatientName(e.target.value);
                  }}
                  label="Name"
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Email"
                  value={patientEmail}
                  onChange={(e) => {
                    enableBtn();
                    setPatientEmail(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Phone"
                  value={patientPhone}
                  onChange={(e) => {
                    enableBtn();
                    setPatientPhone(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    onChange={(e) => {
                      enableBtn();
                      setPatientGender(e.target.value);
                    }}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="FEMALE"
                      control={<Radio />}
                      label="Female"
                      checked={patientGender === "FEMALE"}
                    />
                    <FormControlLabel
                      value="MALE"
                      control={<Radio />}
                      label="Male"
                      checked={patientGender === "MALE"}
                    />
                    <FormControlLabel
                      value="OTHER"
                      control={<Radio />}
                      checked={patientGender === "OTHER"}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  disabled
                  type={"text"}
                  label="ABHA Address"
                  size="small"
                  value={""}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <DatePicker
                  value={patientDOB}
                  onDateChange={(e) => setPatientDOB(e)}
                />
              </Grid>
              <Grid
                item
                sm={12}
                lg={12}
                md={12}
                display="flex"
                justifyContent="right"
              >
                <Button variant="contained">Submit</Button>
              </Grid>
            </Grid>
          )}
          {type === "drug" && ( //MARK:DRUGS
            <Grid container spacing={3} justifyContent="center">
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  value={companyName}
                  onChange={(e) => {
                    enableBtn();
                    setCompanyName(e.target.value);
                  }}
                  label="Company Name"
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Drug Name"
                  value={medicineName}
                  onChange={(e) => {
                    enableBtn();
                    setMedicineName(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Generic Name"
                  value={genericName}
                  onChange={(e) => {
                    enableBtn();
                    setGenericName(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Drug Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={drugType}
                    label="Drug type"
                    onChange={(e) => {
                      enableBtn();
                      {
                        setDrugType(e.target.value);
                      }
                    }}
                  >
                    {drugTypes.map((selectedType, index) => (
                      <MenuItem
                        value={selectedType?._id}
                        key={selectedType?._id}
                      >
                        {selectedType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} lg={6} md={6}>
                <Autocomplete
                  options={allSpecialities}
                  value={
                    allSpecialities.find(
                      (obj) => obj._id === doctorSpeciality?._id
                    ) || null
                  }
                  onChange={(e, newValue) => {
                    enableBtn();
                    console.log(newValue);
                    setdoctorSpeciality(newValue);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="filled"
                        label={`${option.name}`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="specialities"
                      label="Specialization"
                      size="small"
                    />
                  )}
                  loading={isLoading}
                />
              </Grid>
              <Grid item sm={12} lg={6} md={6}></Grid>
            </Grid>
          )}
          {type === "test" && ( //MARK:TESTS
            <Grid container spacing={3} justifyContent="center">
              <Grid item sx={{ width: "100%", m: "3rem 0" }}>
                <TextField
                  fullWidth
                  type={"text"}
                  label="Test"
                  value={testName}
                  onChange={(e) => {
                    enableBtn();
                    setTestName(e.target.value);
                  }}
                  size="medium"
                />
              </Grid>
            </Grid>
          )}
          {type === "Specialization" && ( //MARK:Specialization
            <Grid container spacing={3} justifyContent="center">
              <Grid item sx={{ width: "100%", m: "1rem 0" }}>
                <TextField
                  fullWidth
                  type={"Speciazation"}
                  label="Speciazation"
                  value={speciazationName}
                  onChange={(e) => {
                    enableBtn();
                    setSpeciazationName(e.target.value);
                  }}
                  size="medium"
                />
              </Grid>
            </Grid>
          )}
          {type === "Education" && ( //MARK:Specialization
            <Grid container spacing={3} justifyContent="center">
              <Grid item sx={{ width: "100%", m: "1rem 0" }}>
                <TextField
                  fullWidth
                  type={"Education"}
                  label="Education"
                  value={educationName}
                  onChange={(e) => {
                    enableBtn();
                    setEducationName(e.target.value);
                  }}
                  size="medium"
                />
              </Grid>
            </Grid>
          )}
          {type === "Association" && ( //MARK:Association
            <Grid container spacing={3} justifyContent="center">
              <Grid item sx={{ width: "100%", m: "1rem 0" }}>
                <TextField
                  fullWidth
                  type={"Speciazation"}
                  label="Association"
                  value={associationName}
                  onChange={(e) => {
                    enableBtn();
                    setAssociationName(e.target.value);
                  }}
                  size="medium"
                />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            display="flex"
            justifyContent="right"
          >
            <Button
              sx={{
                marginRight: "15px",
                bgcolor: "white",
                border: "1px solid #194AF5 ",
                color: "#194AF5 ",
                padding: "0.25rem 1.5rem",
                boxShadow: "none",
                ":hover": {
                  bgcolor: "white",
                  border: "1px solid #194AF5 ",
                  boxShadow: "none",
                },
              }}
              variant="contained"
              onClick={() => onClose("close")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={disabled}
              onClick={() => {
                type === "drug" && submitDrugDetails();
                type === "test" && submitTestDetails();
                type === "doctors" && handleSubmitDoctor();
                type === "Specialization" && handleSubmitSpecialization();
                type === "Education" && handleSubmitEducation();
                type === "Association" && handleSubmitAssociation();
              }}
              sx={{ padding: "0.25rem 1.5rem", boxShadow: "none" }}
            >
              Save
            </Button>
          </Grid>
          <ConfirmModal
            open={deleteModal}
            type={type}
            close={() => setDeleteModal(false)}
            confirm={() => {
              type === "drug" && deleteDrug();
              type === "Education" && deleteEducation();

              setDeleteModal(false);
              onClose();
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ModalScreen;
