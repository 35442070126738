import { Backdrop, CircularProgress, Grid, Stack, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import ModalScreen from "../Modal";
import { useOutletContext } from "react-router-dom";

const PatientList = () => {
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [patientData, setPatientData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [isAbha, setIsAbha] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [dist, setDist] = useState("All");
  const [distList, setDistList] = useState(["All"]);
  const { searchText } = useOutletContext();
  const [list, setList] = useState([]);
  const tableHeader = [
    "Name",
    "Phone",
    "Email",
    "Gender",
    "District",
    "Health Id",
  ];
  const keysName = ["name", "phone", "email", "gender", "district", "healthId"];

  const PatientsList = (e) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        ...(e &&
          e.target.value && {
            search: e.target.value,
          }),
        isAbha: isAbha,
        ...(dist && { distFilter: dist }),
      };

      api
        .post("/admin/patients-list", PayLoadDoctorList)
        .then((response) => {
          setCount(response?.data?.data?.totalCount);
          setPatientData(response?.data?.data?.patientList);
          setList(response?.data?.data?.patientList);
          setDistList(["All", ...(response?.data?.data?.distinct || [])]);
          setBackDropOpen(false);
        })
        .catch((err) => {
          setBackDropOpen(false);
          if (err?.response?.data?.message) {
            toast.error(`Error :${err?.response?.data?.message}`);
          } else {
          }
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Doctor list error");
    }
  };
  useEffect(() => {
    const data = list?.filter((x) =>
      x?.name?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    setPatientData(data);
  }, [searchText, list]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageNumber(0);
  };

  const handleModal = (e) => {
    setData(e);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setData({});
    setOpenModal(false);
  };

  useEffect(() => {
    PatientsList();
  }, [rowsPerPage, pageNumber, isAbha, dist]);

  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item sm={12} md={12} lg={12} justifyContent="center" padding={2}>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={backDropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "1rem 0rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => setIsAbha(undefined)}
                sx={{
                  borderBottom: isAbha === undefined && "2px solid #194AF5",
                  color: isAbha === undefined ? "#194AF5" : "#7D8DC4",
                  width: "max-content",
                  padding: "0.5rem 1rem",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                All
              </Box>
              <Box
                onClick={() => setIsAbha(true)}
                sx={{
                  borderBottom: isAbha === true && "2px solid #194AF5",
                  color: isAbha === true ? "#194AF5" : "#7D8DC4",
                  width: "max-content",
                  padding: "0.5rem 1rem",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                With ABHA
              </Box>
              <Box
                onClick={() => setIsAbha(false)}
                sx={{
                  borderBottom: isAbha === false && "2px solid #194AF5",
                  color: isAbha === false ? "#194AF5" : "#7D8DC4",
                  width: "max-content",
                  padding: "0.5rem 1rem",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Without ABHA
              </Box>
            </Box>
          </Stack>
          <Listtable
            tableheader={tableHeader}
            tablebody={patientData}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={count}
            dataKeyName={keysName}
            editHandle={handleModal}
            tag="patients"
          />
        </Grid>
      </Grid>

      {openModal && (
        <ModalScreen
          open={openModal}
          onClose={handleModalClose}
          data={data}
          type="patients"
        />
      )}
    </>
  );
};

export default PatientList;
