import { Box } from "@mui/material";
import React from "react";

function DashboardNav({ setSearchText }) {
  const onChangeSearch = async (e) => {
    setSearchText(e.target.value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          boxShadow: "2px 2px 12px 0px rgba(0, 0, 0, 0.04)",
          bgcolor: "white",
          padding: "0.5rem 1rem",
          borderRadius: "5px",
        }}
      >
        <svg width={16} height={16} viewBox="0 0 28 28" fill="none">
          <path
            d="M20.1349 18.7701C20.2381 18.8231 20.3353 18.8869 20.4249 18.9606C22.8193 21.3495 25.2116 23.7393 27.602 26.13C27.9258 26.4529 28.0912 26.8131 27.9483 27.2685C27.8999 27.4294 27.8127 27.576 27.6944 27.6955C27.5761 27.8149 27.4303 27.9035 27.2698 27.9535C27.1093 28.0035 26.939 28.0134 26.7738 27.9822C26.6086 27.9511 26.4535 27.8799 26.3222 27.7749C26.2306 27.6984 26.1439 27.6163 26.0625 27.529C23.6958 25.1626 21.3292 22.7939 18.9625 20.4229C18.8877 20.3343 18.8205 20.2396 18.7617 20.1398C17.1046 21.5347 15.0847 22.4293 12.9381 22.719C10.7914 23.0086 8.60667 22.6814 6.6391 21.7756C4.67153 20.8697 3.00243 19.4227 1.82684 17.6035C0.651263 15.7843 0.0177604 13.6682 0.000367999 11.5024C-0.0170244 9.33653 0.582412 7.21048 1.72863 5.37267C2.87484 3.53485 4.52049 2.06118 6.47326 1.12386C8.42603 0.186547 10.6053 -0.175695 12.7563 0.0794688C14.9073 0.334633 16.9413 1.19666 18.6205 2.56482C23.3688 6.44863 24.3576 13.6483 20.1349 18.7701ZM2.07332 11.3965C2.07332 16.5425 6.26402 20.7397 11.4089 20.7389C16.5537 20.738 20.7263 16.5616 20.7427 11.4259C20.7466 8.95042 19.7669 6.57478 18.0191 4.8216C16.2712 3.06842 13.8985 2.0813 11.4227 2.0774C10.1969 2.07546 8.98266 2.31498 7.84939 2.78226C6.71611 3.24954 5.68598 3.93543 4.8178 4.80079C3.06445 6.54845 2.07723 8.92098 2.07332 11.3965Z"
            fill="#194AF5"
          />
        </svg>

        <input
          onChange={onChangeSearch}
          type="text"
          style={{
            fontSize: ".8rem",
            width: "20rem",
            marginLeft: "0.5rem",
            border: "none",
            outline: "none",
          }}
          placeholder="Search for name and contact number.."
        />
      </Box>
      <Box
        sx={{
          bgcolor: "#E6EBFF",
          borderRadius: "100%",
          width: "35px",
          height: "35px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <svg width={22} height={22} viewBox="0 0 24 24" fill="none">
          <path
            d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z"
            fill="#194AF5"
          />
          <path
            d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
            fill="#194AF5"
          />
        </svg>
      </Box>
    </Box>
  );
}

export default DashboardNav;
