import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend((lng, ns, cb) => {
      import(`../locales/${lng}.json`)
        .then((data) => console.log() || cb(null, data)) //console.log({ lng })
        .catch((error) => cb(error, null));
    })
  )
  .init({
    fallbackLng: "en",
    debug: false,
  });

export default i18n;
