import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

function ConfirmModal({ open, close, confirm, type }) {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "max-content",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          fontWeight={"600"}
          fontSize={"1rem"}
          align="center"
        >
          {type === "drug" && "Are you sure to delete the drug?"}
          {type === "doctors" && "Are you sure to diasble the doctor?"}
          {type === "subscription" &&
            "Are you sure want to save and replace with new plan?"}
          {type === "Education" &&
            "Are you sure want to delete this Education?"}
        </Typography>
        <Box display="flex" alignItems={"center"} mt={2}>
          <Button
            sx={{
              marginRight: "15px",
              bgcolor: "white",
              border: "1px solid #194AF5 ",
              color: "#194AF5 ",
              padding: "0.25rem 1.5rem",
              boxShadow: "none",
              flex: "1",
              ":hover": {
                bgcolor: "white",
                border: "1px solid #194AF5 ",
                boxShadow: "none",
              },
            }}
            variant="contained"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={confirm}
            sx={{
              padding: "0.25rem 1.5rem",
              boxShadow: "none",
              flex: "1",
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfirmModal;
