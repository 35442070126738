import {
    Autocomplete,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormControl,
    Typography,
    Checkbox
} from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import api from "../../utils/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HospitalModal = ({ open, onClose, medicalFaculties, isEditDoctor }) => {
    const { t } = useTranslation();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };
    const [_id, setID] = useState("");
    const [hospitalName, setHospitalName] = useState("");
    const [hospitalType, setHospitalType] = useState("HOSPITAL");
    const [doctors, setDoctors] = useState([]);
    const [fees, setFee] = useState('');
    const [doctor, setDoctor] = useState('');

    const [doctorsList, setDoctorsList] = useState([]);
    const [status, setStatus] = useState('');
    const [receptionList, setReceptionList] = useState([]);
    const [hospitalAddress, setHospitalAddress] = useState({
        area: "",
        street: "",
        city: "",
        state: "",
        pincode: ""
    });

    useEffect(() => {
        doctorList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (doctorsList.length && Object.entries(medicalFaculties).length > 0 && isEditDoctor) {
            setHospitalName(medicalFaculties?.name);
            setHospitalType(medicalFaculties?.type);
            setHospitalAddress(medicalFaculties?.address);
            const doctors = doctorsList.filter(x => medicalFaculties?.doctors?.includes(x._id));
            setDoctors(doctors);
            setDoctor(medicalFaculties?.doctor);
            setFee(medicalFaculties.fees)
            setStatus(!!(medicalFaculties?.active === 'Active'));
            setID(medicalFaculties?._id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doctorsList]);



    const doctorList = () => {
        try {
            const PayLoadDoctorList = {
                limit: 0,
                page: 1,
            };
            api
                .post(`/admin/doctors-list`, PayLoadDoctorList)
                .then((res) => {
                    const DOCTOR = res?.data?.data?.doctorList.filter(x => x?.role?.type === 'DOCTOR');
                    const RECEPTION = res?.data?.data?.doctorList.filter(x => x?.role?.type === 'RECEPTION');
                    setDoctorsList(DOCTOR || [])
                    setReceptionList(RECEPTION || [])
                })
                .catch((err) => {
                    toast.error("Error " + err);
                });
        } catch (error) {
            toast.error(`Error ${error}`);
        }
    };

    const handleSubmitHospital = () => {
        try {
            const hospitalPayload = {
                name: hospitalName,
                type: hospitalType,
                doctors: doctors,
                status,
                doctor,
                fees,
                _id,
                address: hospitalAddress
            };
            api
                .post(`/hospital/medicalFaclty`, hospitalPayload)
                .then(() => {
                    onClose();
                    toast.success("Hospital Succesfully Created");
                })
                .catch((err) => {
                    toast.error("Error " + err);
                });
        } catch (error) {
            toast.error(`Error ${error}`);
        }
    };

    const handleHospitalAddress = (e) => {
        setHospitalAddress({
            ...hospitalAddress,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={() => onClose('close')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        justifyContent="left"
                        sx={{ mb: 3 }}>
                        <Grid item>
                            <Typography variant="h5" component="h5" align="center">
                                {isEditDoctor ? t('EDIT_MEDIAL_FAC') : t('CREATE_MEDIAL_FAC')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={hospitalName}
                                label="Name"
                                name="name"
                                onChange={(e) => setHospitalName(e.target.value)}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="clinic-type">
                                    Type
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="clinic-type"
                                    id="clinic-type-deoctor"
                                    value={hospitalType}
                                    disabled
                                    label="Doctor Status"
                                    onChange={(e) => setHospitalType(e.target.value)}>
                                    <MenuItem value={'HOSPITAL'}>Hospital</MenuItem>
                                    <MenuItem value={'CLINIC'}>Clinic</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={hospitalAddress.street}
                                label="House/Street/Gali Number"
                                name="street"
                                onChange={handleHospitalAddress}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={hospitalAddress.area}
                                label="Sec/Area/Locality"
                                name="area"
                                onChange={handleHospitalAddress}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={hospitalAddress.city}
                                label="City"
                                name="city"
                                onChange={handleHospitalAddress}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={hospitalAddress.state}
                                label="State"
                                name="state"
                                onChange={handleHospitalAddress}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={hospitalAddress.pincode}
                                label="Pincode"
                                name="pincode"
                                onChange={handleHospitalAddress}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={fees}
                                label="Fees"
                                name="fee"
                                onChange={(e) => setFee(e.target.value)}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="clinic-doc">
                                    Reception
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="clinic-doc"
                                    id="clinic-type-doc"
                                    value={doctor}
                                    label="Reception"
                                    onChange={(e) => setDoctor(e.target.value)}>
                                    {receptionList.map((reception, index) => (
                                        <MenuItem value={reception._id} key={index}>
                                            {reception.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="clinic-doctors-status">
                                    Status
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="clinic-doctors-status"
                                    id="clinic-doctors-status"
                                    value={status}
                                    label="Doctor Status"
                                    onChange={(e) => setStatus(e.target.value)}>
                                    <MenuItem value={true}>ACTIVE</MenuItem>
                                    <MenuItem value={false}>INACTIVE</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={12} lg={12} md={12}>
                            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={doctorsList}
                                    value={doctors}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        setDoctors(value);
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Doctors" />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            lg={12}
                            md={12}
                            display="flex"
                            justifyContent="right">
                            <Button variant="contained" onClick={handleSubmitHospital}>
                                {t('SUBMIT')}
                            </Button>
                            <Button
                                sx={{ marginLeft: "5px" }}
                                variant="contained"
                                onClick={() => onClose('close')}>
                                {t('CANCEL')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
};

export default HospitalModal;
