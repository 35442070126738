import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { deleteTemplate } from "../../api/template";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    height: "auto",
    maxHeight: "60vh",
    width: "35vw",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    zIndex: "1",
  },
  overlay: {
    backgroundColor: "rgba(30, 30, 30, 0.50)",
  },
};
const TemplateListModal = ({ setOpen, open, minortemplates }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const navigate = useNavigate();
  const [id, setId] = useState("");

  const handleDelete = async () => {
    const response = await deleteTemplate(id);
    if (response.status === 200) {
      console.log("Template Deleted");
      setAnchorEl(null);
    } else {
      console.log("Template Not Deleted");
    }
  };
  return (
    <Modal
      onRequestClose={() => {
        setOpen(false);
      }}
      isOpen={open}
      style={customStyles}
    >
      <h1 className="template_modal_header">Templates</h1>
      <div className="template_table_head">Template Name</div>
      {minortemplates &&
        minortemplates?.map((item, index) => (
          <div key={index} className="template_table_card">
            <p>{item.name}</p>
            <svg
              onClick={(e) => {
                setId(item._id);
                setAnchorEl(e.currentTarget);
              }}
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M7.00065 2.33333C7.64498 2.33333 8.16732 1.811 8.16732 1.16667C8.16732 0.522334 7.64498 0 7.00065 0C6.35632 0 5.83398 0.522334 5.83398 1.16667C5.83398 1.811 6.35632 2.33333 7.00065 2.33333Z"
                fill="black"
              />
              <path
                d="M7.00065 8.16732C7.64498 8.16732 8.16732 7.64498 8.16732 7.00065C8.16732 6.35632 7.64498 5.83398 7.00065 5.83398C6.35632 5.83398 5.83398 6.35632 5.83398 7.00065C5.83398 7.64498 6.35632 8.16732 7.00065 8.16732Z"
                fill="black"
              />
              <path
                d="M7.00065 13.9993C7.64498 13.9993 8.16732 13.477 8.16732 12.8327C8.16732 12.1884 7.64498 11.666 7.00065 11.666C6.35632 11.666 5.83398 12.1884 5.83398 12.8327C5.83398 13.477 6.35632 13.9993 7.00065 13.9993Z"
                fill="black"
              />
            </svg>
          </div>
        ))}

      <Menu
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open1}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate(`/edittemplate/${id}`)}>
          <label className="database_menu_item">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
              <g clip-path="url(#clip0_4184_17028)">
                <path
                  d="M6 14.0001H3C2.86739 14.0001 2.74021 13.9474 2.64645 13.8536C2.55268 13.7599 2.5 13.6327 2.5 13.5001V10.707C2.50006 10.5745 2.55266 10.4475 2.64625 10.3538L10.3537 2.64633C10.4475 2.55263 10.5746 2.5 10.7072 2.5C10.8397 2.5 10.9669 2.55263 11.0606 2.64633L13.8537 5.43758C13.9474 5.53134 14.0001 5.65847 14.0001 5.79102C14.0001 5.92357 13.9474 6.0507 13.8537 6.14446L6 14.0001Z"
                  stroke="#194AF5"
                  stroke-width="0.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 14H6"
                  stroke="#194AF5"
                  stroke-width="0.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.5 4.5L12 8"
                  stroke="#194AF5"
                  stroke-width="0.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4184_17028">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            Edit
          </label>
        </MenuItem>
        <MenuItem onClick={() => handleDelete()}>
          <label className="database_menu_item">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path
                d="M6.66602 8.5V11.8333"
                stroke="#F51934"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33398 8.5V11.8333"
                stroke="#F51934"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.66602 5.16602H13.3327"
                stroke="#F51934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 7.16602V12.4993C4 13.6039 4.89543 14.4993 6 14.4993H10C11.1046 14.4993 12 13.6039 12 12.4993V7.16602"
                stroke="#F51934"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 3.83333C6 3.09695 6.59695 2.5 7.33333 2.5H8.66667C9.40307 2.5 10 3.09695 10 3.83333V5.16667H6V3.83333Z"
                stroke="#F51934"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Delete
          </label>
        </MenuItem>
      </Menu>
    </Modal>
  );
};

export default TemplateListModal;
