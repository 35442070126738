import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  Avatar,
  IconButton,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import api from "../../utils/Api";
import { toast } from "react-toastify";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { UpdateDoctorStatus } from "../../api/doctor";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";

function MuiIcon() {
  return (
    <svg
      style={{ marginRight: "10px" }}
      width="30"
      height="30"
      viewBox="0 0 18 10"
      fill="none"
    >
      <path
        d="M16.5 1.5L9 9L1.5 1.5"
        stroke="#626262"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};

const CarouselItem = ({
  number,
  item,
  onFieldChange,
  handleCarouselImageSelection,
  removeCarouselImage,
  removeCarouselItem,
}) => {
  return (
    <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
      <Typography variant="body2" sx={{ fontWeight: "500", marginTop: "25px", alignSelf: "flex-start" }}>
        Link {number}
      </Typography>
      <div style={{ flex: "2 1 0" }}>
        <FormControl fullWidth margin="normal">
          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <InputLabel id={`carlabel${number}`} size="small">
              Select Item Type
            </InputLabel>
            <Select
              value={item.type}
              onChange={(e) => onFieldChange("type", e.target.value)}
              size="small"
              labelId={`carlabel${number}`}
              label="Select Item Type"
              sx={{ height: "fit-content", flex: "1" }}
            >
              <MenuItem value="photo/video">Photo/Video</MenuItem>
              <MenuItem value="video">Video Link</MenuItem>
            </Select>
            {(item.type==="video" || item.type==="photo/video") && (
              <TextField
                fullWidth
                margin="normal"
                label={item.type === "video" ? "Enter Video Link URL" : "Enter Redirect Link"}
                value={item?.link}
                onChange={(e) => onFieldChange("link", e.target.value)}
                size="small"
                sx={{ margin: "0", flex: "1" }}
              />
            )}
            
            
            {item.type !== "video" && item.type !== "photo/video" && (
              <div style={{ flex: "1" }}></div>
            )}
          </div>
          {item.type === "photo/video" && (
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                alignItems: "center",
              }}
            >
              {item.media.url ? (
                <img
                  src={item.media.url}
                  alt="Carousel"
                  style={{ height: "32px", width: "auto" }}
                />
              ) : (
                <ImageIcon />
              )}
              {!item.media.url ? (
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  startIcon={<FileUploadOutlinedIcon />}
                  sx={{
                    color: "#000000",
                    border: "1px solid #E2E8F0",
                    textTransform: "none",
                  }}
                >
                  Upload media
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) =>
                      handleCarouselImageSelection(e, number - 1)
                    }
                  />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    color: "#000000",
                    border: "1px solid #E2E8F0",
                    textTransform: "none",
                    "&:active": {
                      transform: "none", // Override the active state transform
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                  disableRipple
                >
                  <Typography sx={{ marginRight: "8px" }}>
                    Uploaded Image
                  </Typography>
                  <span
                    onClick={(e) => removeCarouselImage(number - 1)} // Click handler only on the icon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </span>
                </Button>
              )}
            </div>
          )}
        </FormControl>
      </div>
      {removeCarouselItem && (
        <IconButton sx={{height: "fit-content"}} onClick={() => removeCarouselItem(number-1)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
};

const VersionModal = ({ open, handleClose, versionData, onUpdate }) => {
  const [formData, setFormData] = useState({
    _id: "",
    name: "",
    domainLink: "",
    versionType: "",
    isActive: true,
    phone: "",
    address: "",
    specialityType: "",
    parent: "",
  });
  const [allParents, setAllParents] = useState([]);

  const getParents = () => {
    console.log("versionData?.versionType", versionData?.versionType);
    if (versionData?.versionType === "speciality") {
    api
      .get(`/specialization`)
      .then((res) => {
        if (res) {
          console.log("Parents res", res?.data?.data?.Specialization);
          setAllParents(res?.data?.data?.Specialization);
        }
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
    } else if (versionData?.versionType === "association") {
      api.get(`/association`)
      .then((res) => {
        if (res) {
          
          setAllParents(res?.data?.data?.Association);
        }
      })
    }
  };

  const [displayPhoto, setDisplayPhoto] = useState({
    link: "",
    deletehash: "",
  });
  const [carouselData, setCarouselData] = useState([]);
  const [parent, setParent] = useState(null);
  useEffect(() => {
    if (versionData) {
      console.log("versionData", versionData);
      setFormData({
        _id: versionData._id || "",
        name: versionData.name || "",
        domainLink: versionData.domainLink || "",
        versionType: versionData.versionType || "",
        isActive: versionData.isActive || false,
        phone: versionData.phone || "",
        address: versionData.address || "",
        specialityType: versionData.specialityType || "",
        parent: versionData.parent || null,
      });
      setDisplayPhoto(versionData.displayPhoto || { link: "", deletehash: "" });
      setCarouselData(versionData.carouselData || []);
      getParents();
    }
  }, [versionData]);

  

  const handleImageSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDisplayPhoto({
        file,
        link: URL.createObjectURL(file),
        deletehash: "",
      });
    }
  };

  const handleCarouselImageSelection = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setCarouselData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          media: {
            file,
            ...newData[index].media,
            url: URL.createObjectURL(file),
          },
        };
        return newData;
      });
    }
  };

  const handleCarouselFieldChange = (index, field, value) => {
    setCarouselData((prevData) => {
      const newData = [...prevData];
      if (field === "media.url") {
        newData[index] = {
          ...newData[index],
          media: {
            ...newData[index].media,
            url: value,
          },
        };
      } else {
        newData[index] = {
          ...newData[index],
          [field]: value,
          number: index + 1,
        };
      }
      return newData;
    });
  };
  
  const handleEmptyCarouselFieldChange = (field, value) => {
    setCarouselData((prevData) => {
      if (prevData.length === 0) {
        // If the array is empty, add a new item
        const newItem = {
          type: "",
          media: { url: "", deletehash: "" },
          redirectUrl: "",
          videoLinkUrl: "",
        };
        newItem[field] = value;
        return [newItem];
      }

      // Check if the last item in the carouselData is empty
      const lastItem = prevData[prevData.length - 1];
      if (
        !lastItem ||
        (lastItem.type === "" &&
          lastItem.media.url === "" &&
          lastItem.redirectUrl === "")
      ) {
        // If the last item is empty, update it
        const newData = [...prevData];
        const emptyItem = {
          type: "",
          media: { url: "", deletehash: "" },
          redirectUrl: "",
          videoLinkUrl: "",
        };
        emptyItem[field] = value;
        newData[newData.length - 1] = emptyItem;
        return newData;
      } else {
        // If the last item is not empty, add a new empty item
        const newItem = {
          type: "",
          media: { url: "", deletehash: "" },
          redirectUrl: "",
          videoLinkUrl: "",
        };
        newItem[field] = value;
        return [...prevData, newItem];
      }
    });
  };

  const removeImage = () => {
    console.log("removeImage");
    setDisplayPhoto({ file: "", link: "", deletehash: "" });
  };
  const removeCarouselItem = (index) => {
    setCarouselData((prevData) => prevData.filter((_, i) => i !== index));
  };
  const uploadImage = async (file) => {
    console.log("file", file);
    const formData = new FormData();
    formData.append("file", file);
    const response = await api.post("/admin/upload-image", formData);
    console.log("response", response);
    return {
      link: response.data.data.url,
      deletehash: response.data.data.deletehash,
    };
  };

  const handleSaveChanges = async () => {
    try {
      let updatedDisplayPhoto = displayPhoto;

      // Upload display photo if it exists
      if (displayPhoto.file) {
        updatedDisplayPhoto = await uploadImage(displayPhoto.file);
      }

      // Upload carousel images
      const updatedCarouselData = await Promise.all(
        carouselData.map(async (item) => {
          if (item.media.file) {
            const uploadedMedia = await uploadImage(item.media.file);
            const { file, url, ...restMedia } = item.media; // Destructure to exclude 'file'
            return { ...item, media: { ...restMedia, url: uploadedMedia.link, deletehash: uploadedMedia.deletehash } };
          }
          return item;
        })
      );
      
      // Update formData with new links and deletehashes
      const updatedFormData = {
        ...formData,
        displayPhoto: updatedDisplayPhoto,
        carouselData: updatedCarouselData,
      };
      console.log("updatedFormData", updatedFormData);

      let response;
      if (formData._id) {
        // Update existing version
        response = await api.patch(
          `/admin/versions/${formData._id}`,
          updatedFormData
        );
      } else {
        // Create new version
        response = await api.post("/admin/versions", updatedFormData);
      }
      if (response.status === 200) {
        toast.success("Version updated successfully");
        onUpdate(updatedFormData);
        handleClose();
      } else if (response.status === 201) {
        toast.success("Version created successfully");
        onUpdate(response.data.data);
        handleClose();
      }
    } catch (error) {
      toast.error("Failed to update version");
      console.error("Error updating version:", error);
    }
  };
  const handleSwitchChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      isActive: event.target.checked,
    }));
  };
  const removeCarouselImage = (index) => {
    setCarouselData((prevData) => {
      // Create a new array with the updated media object
      const newData = prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            media: { url: "", deletehash: "" }, // Reset media properties
          };
        }
        return item;
      });
      return newData;
    });
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <h6>Edit {formData.versionType} Details</h6>
          <Typography variant="body2" sx={{ color: "#64748B" }}>
            Make changes to {formData.versionType} here. Click save when you're
            done.
          </Typography>
          {
            formData.versionType !== "main" && (
              <>
                <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "32px",
                }}
              >
                <p style={{ marginBottom: "0" }}>
                  Set {formData.versionType} as active
                </p>
                <Switch checked={formData.isActive} onChange={handleSwitchChange} />
              </div>
                        <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "500", marginBottom: "12px" }}
                        >
                          Display photo
                        </Typography>
                        <div style={{ display: "flex", gap: "16px" }}>
                          <Avatar
                            sx={{
                              height: "60px",
                              width: "60px",
                              bgcolor: "#ffffff",
                              border: "1px dashed #E2E8F0",
                            }}
                          >
                            {!displayPhoto.link ? (
                              <DomainAddOutlinedIcon sx={{ color: "#64748B" }} />
                            ) : (
                              <img
                                src={displayPhoto.link}
                                alt="Preview"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </Avatar>
                          <div>
                            {!displayPhoto.link ? (
                              <Button
                                component="label"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<FileUploadOutlinedIcon />}
                                sx={{
                                  color: "#000000",
                                  border: "1px solid #E2E8F0",
                                  textTransform: "none",
                                }}
                              >
                                Upload media
                                <input
                                  type="file"
                                  accept="image/*"
                                  hidden
                                  onChange={handleImageSelection}
                                />
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#000000",
                                  border: "1px solid #E2E8F0",
                                  textTransform: "none",
                                  "&:active": {
                                    transform: "none", // Override the active state transform
                                  },
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                disableRipple
                              >
                                <Typography sx={{ marginRight: "8px" }}>
                                  Uploaded Image
                                </Typography>
                                <span
                                  onClick={removeImage} // Click handler only on the icon
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </span>
                              </Button>
                            )}
                            <p style={{ fontSize: "12px" }}>
                              Supported: .jpg, .jpeg, .png, .svg, .gif. Max file size: 10MB
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div style={{ flex: "1" }}>
                          <Typography variant="body2" sx={{ fontWeight: "500" }}>
                            {formData.versionType === "association"
                              ? "Association name"
                              : "Type"}
                          </Typography>
                          <Autocomplete
                              fullWidth
                              margin="normal"
                              options={allParents}
                              getOptionLabel={(option) => `${option.name}`}
                              value={allParents.find(parent => parent._id === formData.parent) || null}
                              onChange={(e, value) =>{
                                setFormData({ ...formData, parent: value?._id, name: value?.name })
                              }}
                              isOptionEqualToValue={(option, value) => option._id === value._id}
                              size="small"
                              renderInput={(params) => <TextField margin="normal" {...params} label={formData.versionType === "association" ? "Enter association name" : "Select speciality type"} />}
                            />
                          {formData.versionType === "association" && (<>
                          <Typography variant="body2" sx={{ fontWeight: "500" }}>Phone No.</Typography>
                            <TextField
                              fullWidth
                              margin="normal"
                              label="Enter association phone"
                              value={formData.phone}
                              onChange={(e) =>
                                setFormData({ ...formData, phone: e.target.value })
                              }
                              size="small"
                            />
                          </>)}
                        </div>
                        <div style={{ flex: "1" }}>
                          <div>
                            <Typography variant="body2" sx={{ fontWeight: "500" }}>
                              Domain link
                            </Typography>
                            <TextField
                              fullWidth
                              margin="normal"
                              label="Enter Domain Link"
                              value={formData.domainLink}
                              onChange={(e) =>
                                setFormData({ ...formData, domainLink: e.target.value })
                              }
                              size="small"
                            />
                          </div>
                          {formData.versionType === "association" && (<>
                          <Typography variant="body2" sx={{ fontWeight: "500" }}>Address</Typography>
                            <TextField
                              fullWidth
                              margin="normal"
                              label="Enter association address"
                              value={formData.address}
                              onChange={(e) =>
                                setFormData({ ...formData, address: e.target.value })
                              }
                              size="small"
                            />
                          </>)}
                        </div>
                      </div>
              </>
            )
          }
          <div>
            <br></br>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              Carousel media links
            </Typography>

            <div style={{ maxHeight: "25vh", overflowY: "auto" }}>
              {carouselData.map((carousel, index) => (
                <CarouselItem
                  key={index}
                  number={index + 1}
                  item={carousel}
                  onFieldChange={(field, value) =>
                    handleCarouselFieldChange(index, field, value)
                  }
                  handleCarouselImageSelection={handleCarouselImageSelection}
                  removeCarouselImage={removeCarouselImage}
                  removeCarouselItem={removeCarouselItem}
                />
              ))}
              {/* Render an empty CarouselItem */}
              <CarouselItem
                number={carouselData.length + 1}
                item={{
                  type: "",
                  media: { url: "", deletehash: "" },
                  redirectUrl: "",
                  videoLinkUrl: "",
                }}
                onFieldChange={handleEmptyCarouselFieldChange}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <Button
              onClick={handleClose}
              sx={{ marginRight: "8px", color: "black", textTransform: "none" }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveChanges}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              disableElevation
            >
              Save changes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default VersionModal;