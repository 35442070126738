import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./utils/AdminContext";
import { ThemeProvider } from "@emotion/react";
import customTheme from "./utils/theme";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserProvider>
      <ThemeProvider theme={customTheme}>
        <App />
        <ToastContainer />
      </ThemeProvider>
    </UserProvider>
  </BrowserRouter>
);
