import { Backdrop, CircularProgress, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";

const DoctorsRequest = () => {
  const [isAbha, setIsAbha] = useState(undefined);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [doctorData, setDoctorData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const tableHeader = [
    "Registration Number",
    "Clinic Name",
    "Name",
    "Phone",
    "Email",
  ];
  const keysName = [
    "registrationNumber",
    "clinicName",
    "name",
    "phone",
    "email",
  ];

  const DoctorList = (e) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        ...(e &&
          e.target.value && {
            search: e.target.value,
          }),
        // limit: rowsPerPage,
        // page: pageNumber + 1,
        doctorRole: isAbha,
      };

      api
        .post("/admin/get_doctor_request", PayLoadDoctorList)
        .then((response) => {
          const list = response?.data?.data;
          setDoctorData(list || []);
          setCount(response?.data?.data?.totalCount);
          setBackDropOpen(false);
        })
        .catch((err) => {
          setBackDropOpen(false);
          if (err?.response?.data?.message) {
            toast.error(`Error :${err?.response?.data?.message}`);
          } else {
            toast.error("Doctor Not Found");
          }
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Doctor list error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPageNumber(0);
  };

  const handleModal = (isEdit, e = {}) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        id: e._id,
      };

      api
        .post("/admin/accept_existing_doctor_request", PayLoadDoctorList)
        .then((response) => {
          setBackDropOpen(false);
          DoctorList();
        })
        .catch((err) => {
          setBackDropOpen(false);
          toast.error(err || "Doctor Not Found");
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Doctor list error");
    }
  };

  useEffect(() => {
    DoctorList();
  }, [rowsPerPage, pageNumber, isAbha]);

  useEffect(() => {
    DoctorList();
  }, []);
  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item sm={12} md={12} lg={12} justifyContent="center" padding={2}>
          <Paper sx={{ p: 2 }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backDropOpen}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Paper>
          <Listtable
            tableheader={tableHeader}
            tablebody={doctorData}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={count}
            dataKeyName={keysName}
            editHandle={(e) => handleModal(true, e)}
            tag="doctorsrequest"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorsRequest;
