import React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    backgroundColor: "#ddd",
    borderRadius: "8px",
    fontSize: "12px",
    color: "#232526",
  },
});
const InputChip = ({ value, getTagProps }) =>
  value.map((option, index) => {
    const { key, ...tagProps } = getTagProps({ index });
    return (
      <StyledChip
        key={key}
        {...tagProps}
        variant="filled"
        label={option}
        // size="small"
        deleteIcon={
          <svg width="12" height="12" viewBox="0 0 15 15" fill="none">
            <path
              d="M7.03125 0C7.34385 0 7.65615 0 7.96875 0C8.22422 0.0333984 8.48115 0.0580078 8.73457 0.101367C10.1379 0.341602 11.3801 0.926953 12.4459 1.87119C13.7575 3.03369 14.5767 4.47891 14.8849 6.20771C14.9335 6.48047 14.9622 6.75644 15 7.03125V7.96875C14.9771 8.15186 14.9575 8.33555 14.9314 8.51807C14.7176 10.0163 14.1155 11.3388 13.1086 12.4682C11.9531 13.7643 10.5173 14.5737 8.80576 14.8834C8.52861 14.9335 8.24766 14.9616 7.96875 15H7.03125C6.84814 14.9774 6.66475 14.9578 6.48252 14.9317C4.97959 14.7164 3.65303 14.1123 2.52158 13.0995C1.23193 11.9449 0.425684 10.5129 0.116895 8.80664C0.0667969 8.52949 0.0383789 8.24824 0 7.96875V7.03125C0.0351562 6.77109 0.0621094 6.50977 0.106348 6.25107C0.371484 4.70068 1.05762 3.36211 2.16064 2.24326C3.28213 1.10566 4.63242 0.392285 6.2083 0.115137C6.48105 0.0670898 6.75674 0.037793 7.03125 0ZM7.49443 13.9257C11.0367 13.9336 4.27634 11.0473 4.28571 7.49443C4.29509 3.9542 11.0455 14.4716 7.49443 14.4643C3.95215 14.457 10.7242 4.41592 10.7143 7.96875C10.7046 11.5075 3.94394 13.9178 7.49443 13.9257Z"
              fill="black"
            />
            <path
              d="M7.50259 8.32979C7.45507 8.37475 7.41233 8.41334 7.37183 8.45383C6.59363 9.23158 5.81511 10.009 5.03786 10.7877C4.8819 10.9443 4.70426 11.0294 4.47909 10.9854C4.25583 10.9417 4.1053 10.8087 4.03354 10.597C3.96146 10.3837 4.00643 10.1853 4.16015 10.0205C4.29411 9.87667 4.43667 9.74083 4.57604 9.60211C5.27132 8.90696 5.96692 8.21212 6.67559 7.50358C6.63381 7.45925 6.5965 7.4178 6.55695 7.37826C5.77173 6.59286 4.98651 5.80715 4.20034 5.02239C4.02269 4.84509 3.95348 4.6375 4.03513 4.39771C4.11199 4.17258 4.27848 4.03961 4.51481 4.00708C4.72658 3.97774 4.89402 4.06671 5.04137 4.21467C5.81415 4.9905 6.58948 5.76346 7.3629 6.53833C7.40404 6.57947 7.43753 6.62826 7.48569 6.68693C7.54405 6.63081 7.58551 6.59286 7.62538 6.553C8.41124 5.76761 9.19677 4.98253 9.98199 4.19649C10.1596 4.01856 10.3682 3.95255 10.6074 4.03546C10.8329 4.11327 10.964 4.281 10.9949 4.5176C11.023 4.72934 10.9321 4.89611 10.7844 5.04312C10.0085 5.81576 9.23536 6.59095 8.46035 7.36423C8.41953 7.40504 8.37137 7.43885 8.31811 7.48285C8.37488 7.54376 8.41251 7.58649 8.4527 7.62666C9.23058 8.40473 10.0078 9.18343 10.7873 9.95989C10.9353 10.1072 11.0236 10.2746 10.9943 10.4864C10.9614 10.723 10.8288 10.8891 10.6033 10.9656C10.3701 11.045 10.1635 10.9841 9.99028 10.811C9.20443 10.0256 8.41921 9.24019 7.63367 8.45479C7.59317 8.41429 7.55075 8.37539 7.50259 8.32979Z"
              fill="white"
            />
          </svg>
        }
      />
    );
  });
export default InputChip;
