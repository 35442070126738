import { Route, Routes } from "react-router-dom";
import Login from "./components/SignIn/Login";
import React from "react";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import DoctorList from "./components/ManageDoctors/Doctorlist";
import DoctorsRequest from "./components/DoctorsRequest/Doctorlist";
import PatientList from "./components/ManagePatients/Patientslist";
import DrugList from "./components/ManageDrugs/DrugsList";
import HospitalList from "./components/Hospital/MedicalFacultyList";
import "./utils/i18n";
import HospitalDoctors from "./components/Hospital/hospitalDoctors";
import Settings from "./components/Settings/Settings";
import TemplateList from "./components/Templates/TemplateList";
import Modal from "react-modal";
import AddTemplate from "./components/Templates/AddTemplate";
import EditTemplate from "./components/Templates/EditTemplate";
import MHVVersions from "./components/MHVVersions/MHVVersions";
Modal.setAppElement("#root");

function App() {
  return (
    <div style={{ background: "#FBFBFB", zIndex: 2 }}>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/addtemplate" element={<AddTemplate />} />
        <Route exact path="/edittemplate/:id" element={<EditTemplate />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<DoctorList />} />
          <Route index path="/doctors" element={<DoctorList />} />
          <Route exact path="mhvversions" element={<MHVVersions />} />
          <Route exact path="patients" element={<PatientList />} />
          <Route exact path="drugs" element={<DrugList />} />
          <Route exact path="settings" element={<Settings />} />
          <Route exact path="template" element={<TemplateList />} />
          <Route exact path="medicalFaculty" element={<HospitalList />} />
          <Route
            exact
            path="medicalFaculty/doctors"
            element={<HospitalDoctors />}
          />
          <Route index exact path="request" element={<DoctorsRequest />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
