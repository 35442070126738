import React, { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";
import "./TemplateList.css";
import TemplateListModal from "./TemplateListModal";
import { useNavigate } from "react-router-dom";
import { getAllTemplate, getSpeacialityTemplates } from "../../api/template";
const TemplateList = () => {
  const [open, setOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [minortemplates, setMinorTemplates] = useState([]);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  async function getSpeacialityList() {
    const response = await getAllTemplate();
    if (response?.status === 200) {
      // console.log(response.data.data);
      setTemplates(response.data.data);
    } else {
      console.log("Error fetching speciality");
    }
  }

  async function getSpeacialityTemplatesByID(id) {
    const response = await getSpeacialityTemplates(id);
    if (response?.status === 200) {
      setMinorTemplates(response.data.data?.templates);
      // setTemplates(response.data.data);
    } else {
      console.log("Error fetching speciality");
    }
  }

  useEffect(() => {
    getSpeacialityList();
  }, []);
  useEffect(() => {
    getSpeacialityTemplatesByID(id);
  }, [id]);
  return (
    <Box padding={2}>
      <div className="template_header">
        <h1>Admin Templates</h1>
        <button onClick={() => navigate("/addtemplate")}>Add Template</button>
      </div>
      <TemplateListModal
        setOpen={setOpen}
        open={open}
        minortemplates={minortemplates}
      />
      <Grid container spacing={2}>
        {templates.map((item, index) => (
          <Grid item xs={4} key={index}>
            <div
              className="category_card"
              onClick={() => {
                setId(item?.specialization?._id);
                setOpen(true);
              }}
            >
              <h1>{item.specialization?.name}</h1>
              <p>
                <span>{item.count}</span> Templates
              </p>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TemplateList;
