import { createTheme } from "@mui/material";
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#194AF5",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#037180",
      contrastText: "#FFFFFF",
    },
  },
});

export default customTheme;
