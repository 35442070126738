import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import freePlanIcon from "../../assets/images/Settings/free.svg";
import StandardPlanIcon from "../../assets/images/Settings/standard.png";

function SubscriptionCard({ data, enableBtn, setPlansToUpdate }) {
  const {
    _id,
    name,
    price: _price,
    duration,
    alertBefore,
    discription,
    defaultPlan,
  } = data;

  const [alert, setAlert] = useState(alertBefore);
  const [expire, setExpire] = useState(duration);
  const [price, setPrice] = useState(_price || 0);

  const handleInputChange = (e, setInput) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Regex to allow only numbers
      setInput(value);
      enableBtn();
    }
  };

  useEffect(() => {
    setPlansToUpdate(_id, {
      price: price,
      duration: expire,
      alertBefore: alert,
    });
  }, [alert, expire, price]);

  return (
    <Box
      sx={{
        margin: "1rem 0",
        padding: "1rem",
        border: "1px solid #F3F3F3",
        borderRadius: "8px",
        width: "450px",
      }}
    >
      <Box
        sx={{
          padding: ".8rem",
          borderRadius: "10px",
          bgcolor: "#F2F5FF",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            padding: ".8rem",
            borderRadius: "8px",
            bgcolor: "#E6EBFF",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {name === "Free Trail" ? (
            <img src={freePlanIcon} alt="offer" width={50} height={50} />
          ) : (
            <img
              src={StandardPlanIcon}
              alt="offer"
              width={38}
              height={30}
              style={{ margin: "10px 6px" }}
            />
          )}
        </Box>
        <Box sx={{ flex: "1", marginLeft: "1rem", height: "100%" }}>
          <Typography variant="h6" fontWeight={"500"}>
            {name}
          </Typography>
          <Typography
            variant="h4"
            fontWeight={"600"}
            display={"flex"}
            gap={1}
            alignItems={"center"}
          >
            ₹ {price}{" "}
            <Typography variant="para" fontSize={"16px"} mt={1}>
              for {expire} days
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "2rem .8rem",
          borderRadius: "10px",
          border: "1px solid #DDD",
          marginTop: "1.5rem",
        }}
      >
        <Typography variant="h6" fontWeight={"500"} mb={"1rem"}>
          Edit Subscription
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ m: ".5rem 0", fontSize: "14px", color: "#626262" }}>
            Price (₹)
          </Typography>
          <TextField
            sx={{
              input: {
                fontWeight: "600",
                border: "1px solid #EFF5F7",
                outline: "none",
              },
            }}
            fullWidth
            type={"text"}
            value={price}
            disabled={name === "Free Trail" ? true : false}
            onChange={(e) => {
              handleInputChange(e, setPrice);
            }}
            size="medium"
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ m: ".5rem 0", fontSize: "14px", color: "#626262" }}>
            Expire in (days)
          </Typography>
          <TextField
            sx={{
              input: {
                fontWeight: "600",
                border: "1px solid #EFF5F7",
                outline: "none",
              },
            }}
            fullWidth
            type={"text"}
            value={expire}
            onChange={(e) => {
              handleInputChange(e, setExpire);
            }}
            size="medium"
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ m: ".5rem 0", fontSize: "14px", color: "#626262" }}>
            Alert After (days)
          </Typography>
          <TextField
            sx={{
              input: {
                fontWeight: "600",
                border: "1px solid #EFF5F7",
                outline: "none",
              },
            }}
            fullWidth
            type={"text"}
            value={alert}
            onChange={(e) => {
              handleInputChange(e, setAlert);
            }}
            size="medium"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SubscriptionCard;
