import { InputBase } from "@mui/material";
import React from "react";

function CustomTextField({ id, placeholder, onChange, value, autoFocus }) {
  return (
    <InputBase
      fullWidth
      id={id}
      placeholder={placeholder}
      name="mobile"
      autoFocus
      onChange={onChange}
      value={value}
      sx={{
        color: "#000",
        fontSize: "18px",
        border: "1px solid #F3F3F3",
        padding: "10px 30px",
        background: "#fff",
        borderRadius: "8px",
        fontWeight: 400,
        "::placeholder": {
          color: "#B7B7B7",
          opacity: 1,
        },
      }}
    />
  );
}

export default CustomTextField;
