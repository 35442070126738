import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  getallDrugType,
  getDatabaseReminders,
  getDoses,
  getSearchedDrugs,
} from "../../api/template";

const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    backgroundColor: "#ddd",
    borderRadius: "4px",
    fontSize: "12px",
    padding: "0",
    color: "#232526",
  },
});
const DrugTable = ({ drugList, setDrugList }) => {
  const [drugTypeList, setDrugTypeList] = useState([]);
  const [dosesList, setDosesList] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const [drugNameValue, setDrugNameValue] = useState("");

  useEffect(() => {
    if (drugNameValue.length > 0) {
      getDrugNameOptions(drugNameValue);
    } else {
      setDrugOptions([]);
    }
  }, [drugNameValue]);

  async function getDrugNameOptions(value) {
    if (value.length > 2) {
      const response = await getSearchedDrugs(value);
      console.log("drugOption", response?.data);
      if (response?.status === 200) {
        setDrugOptions(
          response?.data?.data?.map((option) => ({
            name: option?.name,
            genericName: option?.genricName,
          }))
        );
      } else {
        console.log("error fetching drugs options");
      }
    } else {
      setDrugOptions([]);
    }
  }

  const handleText = (e, index) => {
    e.preventDefault();
    const updatedList = drugList.map((item, i) =>
      index === i
        ? Object.assign(item, {
            [e.target.name]: e.target.value,
          })
        : item
    );
    setDrugList(updatedList);
  };

  const handleDosage = (values, index) => {
    const updatedList = drugList.map((item, i) =>
      index === i
        ? Object.assign(item, {
            dosageTime: values,
          })
        : item
    );
    setDrugList(updatedList);
  };

  const handleDrugName = (value, index) => {
    const updatedList = drugList.map((item, i) =>
      index === i
        ? {
            ...item,
            name: value?.name || value,
            genericName: value?.genericName || "",
          }
        : item
    );
    setDrugList(updatedList);
  };

  const handleDoses = (newValue, index) => {
    const updatedList = drugList.map((item, i) =>
      index === i ? { ...item, doses: newValue || { id: "", name: "" } } : item
    );
    setDrugList(updatedList);
  };

  const removeDrug = (e, value) => {
    let newDrugList = drugList.filter((_, index) => index !== value);
    setDrugList(newDrugList);
  };

  const addDrugRow = () => {
    setDrugList([
      ...drugList,
      {
        name: "",
        drugType: "",
        unit: "",
        dosageTime: [],
        duration: "",
        description: "",
        doses: { id: "", name: "" },
      },
    ]);
  };

  async function getDrugTypeData() {
    const response = await getallDrugType();
    if (response?.status === 200) {
      setDrugTypeList(response.data.data?.DrugTypes);
    } else {
      console.log("error getting Drug Type LIst");
    }
  }
  async function getDosesData() {
    const response = await getDoses();
    if (response?.status === 200) {
      const doesesArray = response.data.data.DosesTypes.map((item) => ({
        id: item._id,
        name: item.name,
      }));
      setDosesList(doesesArray);
    } else {
      console.log("error getDrugTypeData");
    }
  }
  async function getDosageData() {
    const response = await getDatabaseReminders();
    if (response?.status === 200) {
      const remindersArray = response.data.data.Reminders.map((item) => ({
        id: item._id,
        name: item.name,
      }));
      setReminders(remindersArray);
    } else {
      console.log("Error Fetching Reminders");
    }
  }
  useEffect(() => {
    getDosageData();
    getDrugTypeData();
    getDosesData();
  }, []);

  return (
    <div className="prescription_content" id="drugs">
      <label htmlFor="test">Medicines</label>
      <table className="table_prescription">
        <thead>
          <tr>
            <th className="text-left">S.No</th>
            <th className="text-left">Medicine Name</th>
            <th className="text-left">Drug Type</th>
            <th className="text-left">Generic Name</th>
            <th className="text-left">Unit</th>
            <th className="text-left">Duration (days)</th>
            <th className="text-left">Dosage</th>
            <th className="text-left">Dosage Times</th>
            <th className="text-left">Instructions</th>
          </tr>
        </thead>
        <tbody>
          {drugList.map((item, index) => (
            <tr key={index}>
              <td className="sl_number_box">
                <div className="d-flex justify-content-between">
                  {drugList.length > 1 && (
                    <button
                      onClick={(e) => removeDrug(e, index)}
                      className="remove_drug_btn"
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 0C5.383 0 0 5.383 0 12C0 18.617 5.383 24 12 24C18.617 24 24 18.617 24 12C24 5.383 18.617 0 12 0ZM17 13H7V11H17V13Z"
                          fill="#FF6F6F"
                        />
                      </svg>
                    </button>
                  )}
                  {index + 1} .
                </div>
              </td>
              <td className="drugname_input">
                <Autocomplete
                  filterSelectedOptions
                  freeSolo
                  fullWidth
                  disableClearable={true}
                  size="small"
                  id="tags-standard"
                  value={item.name}
                  options={drugOptions}
                  getOptionLabel={(option) => {
                    // Check if option is a string (for freeSolo mode) or an object
                    return typeof option === "string"
                      ? option
                      : option.name || "";
                  }}
                  onInputChange={(e, newInputValue) => {
                    setDrugNameValue(newInputValue);
                    handleDrugName(newInputValue, index);
                  }}
                  onChange={(event, newValue) => {
                    handleDrugName(newValue, index);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  renderOption={(props, option, state) => (
                    <li {...props} key={`${option.name}-${state.index}`}>
                      {option.name}
                    </li>
                  )}
                />
              </td>
              <td className="drugtype_box">
                <FormControl fullWidth>
                  <Select
                    sx={{
                      fontSize: "12px",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderStyle: "none",
                      },
                    }}
                    IconComponent={() => (
                      <svg
                        className="select_drop_icon"
                        width="20"
                        height="20"
                        viewBox="0 0 11 6"
                        fill="none"
                      >
                        <path
                          d="M10.854 0.854028L5.85403 5.85403C5.80759 5.90052 5.75245 5.9374 5.69175 5.96256C5.63105 5.98772 5.56599 6.00067 5.50028 6.00067C5.43457 6.00067 5.36951 5.98772 5.30881 5.96256C5.24811 5.9374 5.19296 5.90052 5.14653 5.85403L0.146528 0.854028C0.0527077 0.760208 0 0.63296 0 0.500278C0 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527074 0.367596 0 0.500278 0C0.63296 0 0.760208 0.0527074 0.854028 0.146528L5.50028 4.7934L10.1465 0.146528C10.193 0.100073 10.2481 0.0632225 10.3088 0.0380812C10.3695 0.0129398 10.4346 0 10.5003 0C10.566 0 10.631 0.0129398 10.6917 0.0380812C10.7524 0.0632225 10.8076 0.100073 10.854 0.146528C10.9005 0.192983 10.9373 0.248133 10.9625 0.30883C10.9876 0.369526 11.0006 0.434581 11.0006 0.500278C11.0006 0.565975 10.9876 0.63103 10.9625 0.691726C10.9373 0.752423 10.9005 0.807573 10.854 0.854028Z"
                          fill="#232526"
                        />
                      </svg>
                    )}
                    name="drugType"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item?.drugType || ""}
                    onChange={(e) => handleText(e, index)}
                  >
                    {drugTypeList.map((item, index) => (
                      <MenuItem
                        sx={{ fontSize: "12px" }}
                        key={index}
                        value={item._id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </td>
              <td className="small_box_generic">
                <TextField
                  id="outlined-controlled"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: { borderRadius: 2 },
                  }}
                  fullWidth
                  multiline
                  name="genericName"
                  maxRows={5}
                  onChange={(e) => handleText(e, index)}
                  defaultValue={item.genericName}
                />
              </td>
              <td className="small_box">
                <input
                  name="unit"
                  type="number"
                  min="0"
                  autoComplete="off"
                  className="prescription_table_input"
                  onChange={(e) => handleText(e, index)}
                  defaultValue={item.unit}
                />
              </td>
              <td className="small_box">
                <input
                  name="duration"
                  type="number"
                  autoComplete="off"
                  className="prescription_table_input"
                  placeholder="0"
                  onChange={(e) => handleText(e, index)}
                  defaultValue={item.duration}
                />
              </td>
              <td className="big_box" id="doses">
                <Autocomplete
                  filterSelectedOptions
                  fullWidth
                  popupIcon={
                    <svg width="12" height="12" viewBox="0 0 11 6" fill="none">
                      <path
                        d="M10.854 0.854028L5.85403 5.85403C5.80759 5.90052 5.75245 5.9374 5.69175 5.96256C5.63105 5.98772 5.56599 6.00067 5.50028 6.00067C5.43457 6.00067 5.36951 5.98772 5.30881 5.96256C5.24811 5.9374 5.19296 5.90052 5.14653 5.85403L0.146528 0.854028C0.0527077 0.760208 0 0.63296 0 0.500278C0 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527074 0.367596 0 0.500278 0C0.63296 0 0.760208 0.0527074 0.854028 0.146528L5.50028 4.7934L10.1465 0.146528C10.193 0.100073 10.2481 0.0632225 10.3088 0.0380812C10.3695 0.0129398 10.4346 0 10.5003 0C10.566 0 10.631 0.0129398 10.6917 0.0380812C10.7524 0.0632225 10.8076 0.100073 10.854 0.146528C10.9005 0.192983 10.9373 0.248133 10.9625 0.30883C10.9876 0.369526 11.0006 0.434581 11.0006 0.500278C11.0006 0.565975 10.9876 0.63103 10.9625 0.691726C10.9373 0.752423 10.9005 0.807573 10.854 0.854028Z"
                        fill="#232526"
                      />
                    </svg>
                  }
                  disableClearable={true}
                  size="small"
                  id="tags-standard"
                  options={dosesList}
                  value={
                    item.doses && (item.doses.id || item.doses.name)
                      ? item.doses
                      : null
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id ||
                    (value.id === "" && value.name === "")
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    handleDoses(newValue, index);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ width: "80px" }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <StyledChip
                        {...getTagProps({ index })}
                        variant="filled"
                        label={option.name}
                        size="small"
                        deleteIcon={
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M7.03125 0C7.34385 0 7.65615 0 7.96875 0C8.22422 0.0333984 8.48115 0.0580078 8.73457 0.101367C10.1379 0.341602 11.3801 0.926953 12.4459 1.87119C13.7575 3.03369 14.5767 4.47891 14.8849 6.20771C14.9335 6.48047 14.9622 6.75644 15 7.03125V7.96875C14.9771 8.15186 14.9575 8.33555 14.9314 8.51807C14.7176 10.0163 14.1155 11.3388 13.1086 12.4682C11.9531 13.7643 10.5173 14.5737 8.80576 14.8834C8.52861 14.9335 8.24766 14.9616 7.96875 15H7.03125C6.84814 14.9774 6.66475 14.9578 6.48252 14.9317C4.97959 14.7164 3.65303 14.1123 2.52158 13.0995C1.23193 11.9449 0.425684 10.5129 0.116895 8.80664C0.0667969 8.52949 0.0383789 8.24824 0 7.96875V7.03125C0.0351562 6.77109 0.0621094 6.50977 0.106348 6.25107C0.371484 4.70068 1.05762 3.36211 2.16064 2.24326C3.28213 1.10566 4.63242 0.392285 6.2083 0.115137C6.48105 0.0670898 6.75674 0.037793 7.03125 0ZM7.49443 13.9257C11.0367 13.9336 4.27634 11.0473 4.28571 7.49443C4.29509 3.9542 11.0455 14.4716 7.49443 14.4643C3.95215 14.457 10.7242 4.41592 10.7143 7.96875C10.7046 11.5075 3.94394 13.9178 7.49443 13.9257Z"
                              fill="black"
                            />
                            <path
                              d="M7.50259 8.32979C7.45507 8.37475 7.41233 8.41334 7.37183 8.45383C6.59363 9.23158 5.81511 10.009 5.03786 10.7877C4.8819 10.9443 4.70426 11.0294 4.47909 10.9854C4.25583 10.9417 4.1053 10.8087 4.03354 10.597C3.96146 10.3837 4.00643 10.1853 4.16015 10.0205C4.29411 9.87667 4.43667 9.74083 4.57604 9.60211C5.27132 8.90696 5.96692 8.21212 6.67559 7.50358C6.63381 7.45925 6.5965 7.4178 6.55695 7.37826C5.77173 6.59286 4.98651 5.80715 4.20034 5.02239C4.02269 4.84509 3.95348 4.6375 4.03513 4.39771C4.11199 4.17258 4.27848 4.03961 4.51481 4.00708C4.72658 3.97774 4.89402 4.06671 5.04137 4.21467C5.81415 4.9905 6.58948 5.76346 7.3629 6.53833C7.40404 6.57947 7.43753 6.62826 7.48569 6.68693C7.54405 6.63081 7.58551 6.59286 7.62538 6.553C8.41124 5.76761 9.19677 4.98253 9.98199 4.19649C10.1596 4.01856 10.3682 3.95255 10.6074 4.03546C10.8329 4.11327 10.964 4.281 10.9949 4.5176C11.023 4.72934 10.9321 4.89611 10.7844 5.04312C10.0085 5.81576 9.23536 6.59095 8.46035 7.36423C8.41953 7.40504 8.37137 7.43885 8.31811 7.48285C8.37488 7.54376 8.41251 7.58649 8.4527 7.62666C9.23058 8.40473 10.0078 9.18343 10.7873 9.95989C10.9353 10.1072 11.0236 10.2746 10.9943 10.4864C10.9614 10.723 10.8288 10.8891 10.6033 10.9656C10.3701 11.045 10.1635 10.9841 9.99028 10.811C9.20443 10.0256 8.41921 9.24019 7.63367 8.45479C7.59317 8.41429 7.55075 8.37539 7.50259 8.32979Z"
                              fill="white"
                            />
                          </svg>
                        }
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {option.name}
                    </li>
                  )}
                />
              </td>
              <td className="big_box">
                <Autocomplete
                  sx={{
                    ".MuiAutocomplete-root": {
                      // Adjusting the root of the autocomplete
                      padding: "0px !important",
                      margin: "0px !important",
                    },
                    ".MuiInputBase-root": {
                      // For the input base (underlying element)
                      padding: "0px !important",
                      margin: "0px !important",
                    },
                  }}
                  fullWidth
                  popupIcon={
                    <svg width="12" height="12" viewBox="0 0 11 6" fill="none">
                      <path
                        d="M10.854 0.854028L5.85403 5.85403C5.80759 5.90052 5.75245 5.9374 5.69175 5.96256C5.63105 5.98772 5.56599 6.00067 5.50028 6.00067C5.43457 6.00067 5.36951 5.98772 5.30881 5.96256C5.24811 5.9374 5.19296 5.90052 5.14653 5.85403L0.146528 0.854028C0.0527077 0.760208 0 0.63296 0 0.500278C0 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527074 0.367596 0 0.500278 0C0.63296 0 0.760208 0.0527074 0.854028 0.146528L5.50028 4.7934L10.1465 0.146528C10.193 0.100073 10.2481 0.0632225 10.3088 0.0380812C10.3695 0.0129398 10.4346 0 10.5003 0C10.566 0 10.631 0.0129398 10.6917 0.0380812C10.7524 0.0632225 10.8076 0.100073 10.854 0.146528C10.9005 0.192983 10.9373 0.248133 10.9625 0.30883C10.9876 0.369526 11.0006 0.434581 11.0006 0.500278C11.0006 0.565975 10.9876 0.63103 10.9625 0.691726C10.9373 0.752423 10.9005 0.807573 10.854 0.854028Z"
                        fill="#232526"
                      />
                    </svg>
                  }
                  disableClearable={true}
                  size="small"
                  multiple
                  id="tags-standard"
                  options={reminders}
                  value={item.dosageTime ? item.dosageTime : null}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.name}
                  name="dosage"
                  onChange={(event, newValue) => {
                    handleDosage(newValue, index);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ width: "170px" }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...otherProps } = getTagProps({ index });

                      return (
                        <StyledChip
                          key={key}
                          {...otherProps}
                          variant="filled"
                          label={option.name}
                          size="small"
                          deleteIcon={
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M7.03125 0C7.34385 0 7.65615 0 7.96875 0C8.22422 0.0333984 8.48115 0.0580078 8.73457 0.101367C10.1379 0.341602 11.3801 0.926953 12.4459 1.87119C13.7575 3.03369 14.5767 4.47891 14.8849 6.20771C14.9335 6.48047 14.9622 6.75644 15 7.03125V7.96875C14.9771 8.15186 14.9575 8.33555 14.9314 8.51807C14.7176 10.0163 14.1155 11.3388 13.1086 12.4682C11.9531 13.7643 10.5173 14.5737 8.80576 14.8834C8.52861 14.9335 8.24766 14.9616 7.96875 15H7.03125C6.84814 14.9774 6.66475 14.9578 6.48252 14.9317C4.97959 14.7164 3.65303 14.1123 2.52158 13.0995C1.23193 11.9449 0.425684 10.5129 0.116895 8.80664C0.0667969 8.52949 0.0383789 8.24824 0 7.96875V7.03125C0.0351562 6.77109 0.0621094 6.50977 0.106348 6.25107C0.371484 4.70068 1.05762 3.36211 2.16064 2.24326C3.28213 1.10566 4.63242 0.392285 6.2083 0.115137C6.48105 0.0670898 6.75674 0.037793 7.03125 0ZM7.49443 13.9257C11.0367 13.9336 4.27634 11.0473 4.28571 7.49443C4.29509 3.9542 11.0455 14.4716 7.49443 14.4643C3.95215 14.457 10.7242 4.41592 10.7143 7.96875C10.7046 11.5075 3.94394 13.9178 7.49443 13.9257Z"
                                fill="black"
                              />
                              <path
                                d="M7.50259 8.32979C7.45507 8.37475 7.41233 8.41334 7.37183 8.45383C6.59363 9.23158 5.81511 10.009 5.03786 10.7877C4.8819 10.9443 4.70426 11.0294 4.47909 10.9854C4.25583 10.9417 4.1053 10.8087 4.03354 10.597C3.96146 10.3837 4.00643 10.1853 4.16015 10.0205C4.29411 9.87667 4.43667 9.74083 4.57604 9.60211C5.27132 8.90696 5.96692 8.21212 6.67559 7.50358C6.63381 7.45925 6.5965 7.4178 6.55695 7.37826C5.77173 6.59286 4.98651 5.80715 4.20034 5.02239C4.02269 4.84509 3.95348 4.6375 4.03513 4.39771C4.11199 4.17258 4.27848 4.03961 4.51481 4.00708C4.72658 3.97774 4.89402 4.06671 5.04137 4.21467C5.81415 4.9905 6.58948 5.76346 7.3629 6.53833C7.40404 6.57947 7.43753 6.62826 7.48569 6.68693C7.54405 6.63081 7.58551 6.59286 7.62538 6.553C8.41124 5.76761 9.19677 4.98253 9.98199 4.19649C10.1596 4.01856 10.3682 3.95255 10.6074 4.03546C10.8329 4.11327 10.964 4.281 10.9949 4.5176C11.023 4.72934 10.9321 4.89611 10.7844 5.04312C10.0085 5.81576 9.23536 6.59095 8.46035 7.36423C8.41953 7.40504 8.37137 7.43885 8.31811 7.48285C8.37488 7.54376 8.41251 7.58649 8.4527 7.62666C9.23058 8.40473 10.0078 9.18343 10.7873 9.95989C10.9353 10.1072 11.0236 10.2746 10.9943 10.4864C10.9614 10.723 10.8288 10.8891 10.6033 10.9656C10.3701 11.045 10.1635 10.9841 9.99028 10.811C9.20443 10.0256 8.41921 9.24019 7.63367 8.45479C7.59317 8.41429 7.55075 8.37539 7.50259 8.32979Z"
                                fill="white"
                              />
                            </svg>
                          }
                        />
                      );
                    })
                  }
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        padding: "0 0 0 .5rem",
                        margin: "0",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                      }}
                    >
                      {option.name}
                      <Checkbox size="small" checked={selected} />
                    </li>
                  )}
                />
              </td>
              <td className="big_box_ins">
                <TextField
                  autoComplete="off"
                  id="outlined-controlled"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: { borderRadius: 2 },
                  }}
                  fullWidth
                  multiline
                  name="description"
                  maxRows={5}
                  onChange={(e) => handleText(e, index)}
                  defaultValue={item.description}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-3">
        <button className="addrow_btn" onClick={addDrugRow}>
          <svg width="14" height="14" viewBox="0 0 16 17" fill="none">
            <g clipPath="url(#clip0_1678_24428)">
              <path
                d="M8 3.83301V13.1663"
                stroke="#232526"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.33203 8.5H12.6654"
                stroke="#232526"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1678_24428">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          Add
        </button>
      </div>
    </div>
  );
};

export default DrugTable;
