import { Backdrop, CircularProgress, Box, Stack, Button, Typography, Switch, IconButton, Menu, MenuItem } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VersionModal from "./VersionModal";

const MHVVersions = () => {
  const [type, setType] = useState("association");
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [versions, setVersions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [doctorCounts, setDoctorCounts] = useState([]);
  const tableHeader = [
    "",
    type === "speciality" ? "Type" : "Name", // Conditional expression
    "Domain link",
    "Doctors",
    "Carousel",
    "",
  ];
  // ... existing code ...

const handleVersionUpdate = (updatedVersion) => {
  setVersions((prevVersions) => {
    const versionExists = prevVersions.some((version) => version._id === updatedVersion._id);
    if (versionExists) {
      return prevVersions.map((version) =>
        version._id === updatedVersion._id ? updatedVersion : version
      );

    } else {
      getDoctorCounts();
      return [...prevVersions, updatedVersion];
    }
  });
};

// ... existing code ...
  const fetchVersions = async () => {
    try {
      setBackDropOpen(true);
      const response = await api.get('/admin/versions');
      setVersions(response.data.data);
      setBackDropOpen(false);
      console.log(response.data.data);
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Failed to fetch versions");
      console.error("Error fetching versions:", error);
    }
  };

  const getDoctorCounts = async () => {
    const response = await api.get(`/admin/versions/doctor-counts`);
    setDoctorCounts(response.data.data);
  }

  const createNewVersion = () => {
    setSelectedVersion({
      versionType: type, // Default type, adjust as needed
      name: "",
      displayPhoto: { link: "", deletehash: "" },
      domainLink: "",
      isActive: true,
      carouselData: [
        
      ],
      specialityType: "",
      phone: "", // Optional, initialize as empty string
      address: "", // Optional, initialize as empty string
      parent: null,
    });
    setOpenModal(true);
  };

  

  useEffect(() => {
    fetchVersions();
    getDoctorCounts();
  }, []);

  const handleEditClick = (version) => {
    setOpenModal(true);
    setSelectedVersion(version);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedVersion(null);
  };

  const handleMenuClick = (event,version) => {
    setSelectedVersion(version);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleVersionActiveStatus = async (version) => {
    const updatedVersion = { ...version, isActive: !version.isActive };
    try {
      const response = await api.patch(`/admin/versions/${version._id}`, updatedVersion);
      if (response.data.success) {
        setVersions((prevVersions) =>
          prevVersions.map((v) =>
            v._id === version._id ? { ...v, isActive: updatedVersion.isActive } : v
          )
        );
        toast.success("Version status updated successfully");
      } else {
        toast.error("Failed to update version status");
      }
    } catch (error) {
      toast.error("Error updating version status");
      console.error("Error updating version status:", error);
    }
  };

  


  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    api.delete(`/admin/versions/${selectedVersion._id}`).then(res => {
      if(res.data.success){
        fetchVersions();
        toast.success("Version deleted successfully");
      }else{
        toast.error(res.data.message);
      }
    }).catch(err => {
      toast.error("Failed to delete version");
      console.error("Error deleting version:", err);
    }).finally(() => {
      handleMenuClose();
    });
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
  };


  
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ fontWeight: "500", fontSize: "1.1rem", margin: "1rem 0" }}>
        List of all versions
      </Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" margin="1rem 0">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {["main", "association", "speciality"].map((versionType) => (
            <Box
              key={versionType}
              onClick={() => setType(versionType)}
              sx={{
                borderBottom: type === versionType ? "2px solid #194AF5" : "1px solid transparent",
                color: type === versionType ? "#194AF5" : "#7D8DC4",
                padding: "0.5rem 1rem",
                fontWeight: "600",
                fontSize: '16px',
                cursor: "pointer",
                display: "flex",
                alignItems: 'center',
                gap: "12px"
              }}
            >
              <Box>{versionType==="association" ? "Associations" : versionType === "main" ? "Main" : "Specialities"}</Box>
              <Box style={{ fontWeight: "500", fontSize: '14px' }}>
                {versions.filter(v => v.versionType === versionType).length}
              </Box>
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<AddIcon />}
          sx={{ textTransform: "none", borderRadius: "8px", fontSize: "14px", padding: "10px 20px" }}
          onClick={createNewVersion}
        >
          Create new
        </Button>
      </Stack>

      <div style={{ display: 'grid', gridTemplateColumns: '72px 10fr 11fr 5fr 8fr auto', rowGap: '18px', alignItems: "center" }}>
        <div style={{ display: "grid", gridTemplateColumns: "subgrid", backgroundColor: '#f9f9f9', borderRadius: '5px', gridColumn: '1/-1', border: "1px solid #DDDDDD" }}>
        <div style={{ padding: '8px', alignSelf: "stretch" }}>
            <Typography style={{ fontWeight: "500", fontSize: "14px", color: "#727272", letterSpacing: '0.15px' }}>
              {tableHeader[0]}
            </Typography>
          </div>
          <div style={{ padding: '8px', alignSelf: "stretch" }}>
            <Typography style={{ fontWeight: "500", fontSize: "14px", color: "#727272", letterSpacing: '0.15px' }}>
              {tableHeader[1]}
            </Typography>
          </div>
          <div style={{ padding: '8px', alignSelf: "stretch" }}>
            <Typography style={{ fontWeight: "500", fontSize: "14px", color: "#727272", letterSpacing: '0.15px' }}>
              {tableHeader[2]}
            </Typography>
          </div>
          {
            type !== "main" && (
              <div style={{ padding: '8px', alignSelf: "stretch" }}>
                <Typography style={{ fontWeight: "500", fontSize: "14px", color: "#727272", letterSpacing: '0.15px' }}>
                  {tableHeader[3]}
                </Typography>
              </div>
            )
          }
          <div style={{ padding: '8px', alignSelf: "stretch" }}>
            <Typography style={{ fontWeight: "500", fontSize: "14px", color: "#727272", letterSpacing: '0.15px' }}>
              {tableHeader[4]}
            </Typography>
          </div>
          <div style={{ padding: '8px', alignSelf: "stretch" }}>
            <Typography style={{ fontWeight: "500", fontSize: "14px", color: "#727272", letterSpacing: '0.15px' }}>
              {tableHeader[5]}
            </Typography>
          </div>
        </div>

        {versions.filter(version => version.versionType === type).map((version, index) => (
          <div key={index} style={{ display: 'grid', gridTemplateColumns: 'subgrid', gridColumn: '1/-1', alignItems: 'center', borderRadius: '5px', border: "1px solid #dddddd" }}>
            <div style={{ padding: '8px' }}>
              <div style={{ width: "fit-content", margin: "0 auto" }}>
                {version.displayPhoto.link ? <img src={version.displayPhoto.link} alt={version.name} style={{ width: 36, height: 36 }} /> : <Box sx={{ width: 36, height: 36, backgroundColor: "#F9F9F9", border: "1px solid #DDDDDD", borderRadius: "18px" }}></Box>}
              
              </div>
            </div>
            <div style={{ padding: '8px' }}>{version.versionType === "association" ? <Typography variant="body2">{version.name} </Typography> : <div style={{ fontSize: "14px", color: "#4976F4", borderRadius: "100px", padding: "2px 12px", backgroundColor: "#EDF2FE", width: "fit-content", fontWeight: "600", lineHeight: "1" }}>{version.name}</div>}</div>
            <div style={{ padding: '8px' }}><Typography variant="body2">{version.domainLink}</Typography></div>
            {
              version.versionType !== "main" && (
                <div style={{ padding: '8px' }}><Typography variant="body2" color="primary">{doctorCounts.find(count => count.versionId === version._id)?.doctorCount || 0}</Typography></div>
              )
            }
            <div style={{ padding: '8px' }}><Typography variant="body2">{version.carouselData.length>0 ? `${version.carouselData.length} active links`:"None added"}</Typography></div>
            <div style={{ padding: '8px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {
                version.versionType !== "main" && (
                  <Switch checked={version.isActive} onChange={() => toggleVersionActiveStatus(version)}/>
                )
              }
              <IconButton onClick={() => handleEditClick(version)}><EditIcon /></IconButton>
              {
                version.versionType !== "main" && (
                  <IconButton onClick={(e) => handleMenuClick(e,version)}><MoreVertIcon /></IconButton>
                )
              }
            </div>
          </div>
        ))}
      </div>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} elevation={1}>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>

      <Dialog
        open={confirmDeleteOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this version? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary" variant="contained" disableElevation>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="outlined" disableElevation>
            Delete
          </Button>
        </DialogActions>
      </Dialog>


      {openModal && (
        <VersionModal
          open={openModal}
          handleClose={handleModalClose}
          versionData={selectedVersion}
          onUpdate={handleVersionUpdate}
        />
      )}
    </>
  );
};

export default MHVVersions;