import { TextField, Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import DrugTable from "./DrugTable";
import InputChip from "../../common/InputChip";
import "./AddTemplate.css";
import {
  createTemplate,
  getDoctorSpecialization,
  getSearchedDignoses,
  getSearchedTests,
  getSearchedTreatments,
} from "../../api/template";

const drugTemplate = {
  name: "",
  drugType: "",
  unit: "",
  genericName: "",
  dosageTime: [],
  duration: "",
  description: "",
  doses: { id: "", name: "" },
};
const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    backgroundColor: "#ddd",
    borderRadius: "4px",
    fontSize: ".9rem",
    padding: "0",
    color: "#232526",
  },
  ".MuiChip-deleteIcon": {
    color: "#0363C4",
  },
});
const CustomClearIcon = (props) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" {...props}>
    <path
      d="M7.03125 0.5C7.34385 0.5 7.65615 0.5 7.96875 0.5C8.22422 0.533398 8.48115 0.558008 8.73457 0.601367C10.1379 0.841602 11.3801 1.42695 12.4459 2.37119C13.7575 3.53369 14.5767 4.97891 14.8849 6.70771C14.9335 6.98047 14.9622 7.25644 15 7.53125V8.46875C14.9771 8.65186 14.9575 8.83555 14.9314 9.01807C14.7176 10.5163 14.1155 11.8388 13.1086 12.9682C11.9531 14.2643 10.5173 15.0737 8.80576 15.3834C8.52861 15.4335 8.24766 15.4616 7.96875 15.5H7.03125C6.84814 15.4774 6.66475 15.4578 6.48252 15.4317C4.97959 15.2164 3.65303 14.6123 2.52158 13.5995C1.23193 12.4449 0.425684 11.0129 0.116895 9.30664C0.0667969 9.02949 0.0383789 8.74824 0 8.46875V7.53125C0.0351562 7.27109 0.0621094 7.00977 0.106348 6.75107C0.371484 5.20068 1.05762 3.86211 2.16064 2.74326C3.28213 1.60566 4.63242 0.892285 6.2083 0.615137C6.48105 0.56709 6.75674 0.537793 7.03125 0.5ZM7.49443 14.4257C11.0367 14.4336 4.27634 11.5473 4.28571 7.99443C4.29509 4.4542 11.0455 14.9716 7.49443 14.9643C3.95215 14.957 10.7242 4.91592 10.7143 8.46875C10.7046 12.0075 3.94394 14.4178 7.49443 14.4257Z"
      fill="black"
    />
    <path
      d="M7.50259 8.82979C7.45507 8.87475 7.41233 8.91334 7.37183 8.95383C6.59363 9.73158 5.81511 10.509 5.03786 11.2877C4.8819 11.4443 4.70426 11.5294 4.47909 11.4854C4.25583 11.4417 4.1053 11.3087 4.03354 11.097C3.96146 10.8837 4.00643 10.6853 4.16015 10.5205C4.29411 10.3767 4.43667 10.2408 4.57604 10.1021C5.27132 9.40696 5.96692 8.71212 6.67559 8.00358C6.63381 7.95925 6.5965 7.9178 6.55695 7.87826C5.77173 7.09286 4.98651 6.30715 4.20034 5.52239C4.02269 5.34509 3.95348 5.1375 4.03513 4.89771C4.11199 4.67258 4.27848 4.53961 4.51481 4.50708C4.72658 4.47774 4.89402 4.56671 5.04137 4.71467C5.81415 5.4905 6.58948 6.26346 7.3629 7.03833C7.40404 7.07947 7.43753 7.12826 7.48569 7.18693C7.54405 7.13081 7.58551 7.09286 7.62538 7.053C8.41124 6.26761 9.19677 5.48253 9.98199 4.69649C10.1596 4.51856 10.3682 4.45255 10.6074 4.53546C10.8329 4.61327 10.964 4.781 10.9949 5.0176C11.023 5.22934 10.9321 5.39611 10.7844 5.54312C10.0085 6.31576 9.23536 7.09095 8.46035 7.86423C8.41953 7.90504 8.37137 7.93885 8.31811 7.98285C8.37488 8.04376 8.41251 8.08649 8.4527 8.12666C9.23058 8.90473 10.0078 9.68343 10.7873 10.4599C10.9353 10.6072 11.0236 10.7746 10.9943 10.9864C10.9614 11.223 10.8288 11.3891 10.6033 11.4656C10.3701 11.545 10.1635 11.4841 9.99028 11.311C9.20443 10.5256 8.41921 9.74019 7.63367 8.95479C7.59317 8.91429 7.55075 8.87539 7.50259 8.82979Z"
      fill="white"
    />
  </svg>
);
const AddTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  //   const { setSnackbar } = useAppContext();
  const [templateName, setTemplateName] = useState("");
  const [drugList, setDrugList] = useState([drugTemplate]);
  const [selectedComplains, setSelectedComplains] = useState([]);

  const [selectedDiagnose, setSelectedDiagnose] = useState([]);
  const [diagnoseValue, setDiagnoseValue] = useState("");
  const [diagnosesOptions, setDiagnosesOptions] = useState([]);

  const [selectedTreatment, setSelectedTreatment] = useState([]);
  const [treatmentValue, setTreatmentValue] = useState("");
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [specialitiesList, setSpecialityList] = useState([]);
  const [testValue, setTestValue] = useState("");
  const [testOptions, setTestOptions] = useState([]);
  const [selectedTests, setSelectedTests] = useState([{ name: "" }]);

  const [advice, setAdvice] = useState("");
  const [dentist, setIsDentist] = useState(false);
  const [oralTags, setOralTags] = useState([]);
  const [speciality, setSpeciality] = useState(null);

  async function getSpeacialityList() {
    const response = await getDoctorSpecialization();
    if (response?.status === 200) {
      setSpecialityList(response.data.data.Specialization);
    } else {
      console.log("Error fetching speciality");
    }
  }

  useEffect(() => {
    getSpeacialityList();
  }, []);

  useEffect(() => {
    if (speciality?._id === "66961a8202aeabcc15767ffc") {
      setIsDentist(true);
    } else {
      setIsDentist(false);
    }
  }, [speciality]);

  useEffect(() => {
    if (diagnoseValue.length > 0) {
      getDiagnoseOptions(diagnoseValue);
    } else {
      setDiagnosesOptions([]);
    }
  }, [diagnoseValue]);

  //   useEffect(() => {
  //     if (treatmentValue.length > 0) {
  //       getTreatmentOptions(treatmentValue);
  //     } else {
  //       setTreatmentOptions([]);
  //     }
  //   }, [treatmentValue]);

  useEffect(() => {
    if (testValue.length > 0) {
      getTestOptions(testValue);
    } else {
      setTestOptions([]);
    }
  }, [testValue]);

  async function getDiagnoseOptions(value) {
    const response = await getSearchedDignoses(value);
    console.log("response", response);
    if (response?.status === 200) {
      setDiagnosesOptions(response.data.data.map((option) => option.name));
    } else {
      console.log("error fetching diagnose");
    }
  }

  async function getTreatmentOptions(value) {
    const response = await getSearchedTreatments(value);
    if (response?.status === 200) {
      setTreatmentOptions(response?.data?.data?.map((option) => option.name));
    } else {
      console.log("error fetching Treatments");
    }
  }

  async function getTestOptions(value) {
    const response = await getSearchedTests(value);
    if (response?.status === 200) {
      console.log("tt", response?.data);
      setTestOptions(response?.data?.data?.map((option) => option.name));
    } else {
      console.log("error fetching tests");
    }
  }

  const handleAddTest = (index, newValue) => {
    setSelectedTests((prev) =>
      prev.map((item, i) => (i === index ? { ...item, name: newValue } : item))
    );
  };

  const addTest = () => {
    setSelectedTests([...selectedTests, { name: "" }]);
  };

  const handleSubmit = async () => {
    const processedDrugs = drugList.map((drug) => {
      return {
        ...drugTemplate,
        name: drug.name,
        drugType: drug.drugType,
        genericName: drug.genericName,
        unit: drug.unit,
        dosageTime: drug.dosageTime.map((item) => item.id),
        duration: drug.duration,
        description: drug.description,
        doses: drug.doses.id,
      };
    });
    const maindrugs = processedDrugs?.filter(
      (drug) => drug.name?.trim() !== ""
    );
    const maintest = selectedTests?.filter((test) => test.name?.trim() !== "");
    const data = {
      name: templateName,
      diagnosis: selectedDiagnose,
      complain: selectedComplains,
      tests: maintest,
      treatment: selectedTreatment,
      specialization: speciality._id,
      drugs: maindrugs,
      generalAdvice: advice,
      ...(dentist && { intraOralFindings: oralTags }),
    };
    console.log("template data", data);
    const response = await createTemplate(data);
    if (response?.status === 201) {
      console.log("Added Successdully");
      navigate(-1);
    } else {
      console.log("Added Failed");
    }
  };
  return (
    <div
      style={{
        background: "#fbfbfb",
        padding: "2rem 0",
      }}
    >
      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 2rem",
          background: "#fff",
        }}
      >
        <div className="d-flex justify-content-between gap-2 w-100">
          <div className="w-50">
            <label
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                paddingBottom: "10px",
              }}
            >
              Template Name
            </label>{" "}
            <br />
            <TextField
              fullWidth
              size="small"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </div>
          <div className="w-50">
            <label
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                paddingBottom: "10px",
              }}
            >
              Specialization
            </label>{" "}
            <br />
            <Autocomplete
              fullWidth
              disableClearable={true}
              value={speciality}
              onChange={(event, newValue) => {
                setSpeciality(newValue);
              }}
              size="small"
              id="tags-standard"
              options={specialitiesList}
              getOptionLabel={(option) => option.name}
              name="Specialization"
              renderInput={(params) => (
                <TextField
                  size="small"
                  label="Specialization"
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "0.9rem",
                  }}
                >
                  {option.name}
                </li>
              )}
            />
          </div>
        </div>
        <div className="prescription_content" id="complain">
          <label htmlFor="complain" style={{ fontSize: "0.9rem" }}>
            Complain
          </label>
          <Autocomplete
            freeSolo
            fullWidth
            size="small"
            popupIcon={
              <svg width="12" height="12" viewBox="0 0 11 6" fill="none">
                <path
                  d="M10.854 0.854028L5.85403 5.85403C5.80759 5.90052 5.75245 5.9374 5.69175 5.96256C5.63105 5.98772 5.56599 6.00067 5.50028 6.00067C5.43457 6.00067 5.36951 5.98772 5.30881 5.96256C5.24811 5.9374 5.19296 5.90052 5.14653 5.85403L0.146528 0.854028C0.0527077 0.760208 0 0.63296 0 0.500278C0 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527074 0.367596 0 0.500278 0C0.63296 0 0.760208 0.0527074 0.854028 0.146528L5.50028 4.7934L10.1465 0.146528C10.193 0.100073 10.2481 0.0632225 10.3088 0.0380812C10.3695 0.0129398 10.4346 0 10.5003 0C10.566 0 10.631 0.0129398 10.6917 0.0380812C10.7524 0.0632225 10.8076 0.100073 10.854 0.146528C10.9005 0.192983 10.9373 0.248133 10.9625 0.30883C10.9876 0.369526 11.0006 0.434581 11.0006 0.500278C11.0006 0.565975 10.9876 0.63103 10.9625 0.691726C10.9373 0.752423 10.9005 0.807573 10.854 0.854028Z"
                  fill="#232526"
                />
              </svg>
            }
            disableClearable={true}
            multiple
            id="tags-standard"
            value={selectedComplains}
            onChange={(e, newValue) => {
              setSelectedComplains(newValue);
            }}
            options={[]}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <StyledChip
                    key={key}
                    {...tagProps}
                    variant="filled"
                    label={option}
                    size="small"
                    deleteIcon={
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M7.03125 0C7.34385 0 7.65615 0 7.96875 0C8.22422 0.0333984 8.48115 0.0580078 8.73457 0.101367C10.1379 0.341602 11.3801 0.926953 12.4459 1.87119C13.7575 3.03369 14.5767 4.47891 14.8849 6.20771C14.9335 6.48047 14.9622 6.75644 15 7.03125V7.96875C14.9771 8.15186 14.9575 8.33555 14.9314 8.51807C14.7176 10.0163 14.1155 11.3388 13.1086 12.4682C11.9531 13.7643 10.5173 14.5737 8.80576 14.8834C8.52861 14.9335 8.24766 14.9616 7.96875 15H7.03125C6.84814 14.9774 6.66475 14.9578 6.48252 14.9317C4.97959 14.7164 3.65303 14.1123 2.52158 13.0995C1.23193 11.9449 0.425684 10.5129 0.116895 8.80664C0.0667969 8.52949 0.0383789 8.24824 0 7.96875V7.03125C0.0351562 6.77109 0.0621094 6.50977 0.106348 6.25107C0.371484 4.70068 1.05762 3.36211 2.16064 2.24326C3.28213 1.10566 4.63242 0.392285 6.2083 0.115137C6.48105 0.0670898 6.75674 0.037793 7.03125 0ZM7.49443 13.9257C11.0367 13.9336 4.27634 11.0473 4.28571 7.49443C4.29509 3.9542 11.0455 14.4716 7.49443 14.4643C3.95215 14.457 10.7242 4.41592 10.7143 7.96875C10.7046 11.5075 3.94394 13.9178 7.49443 13.9257Z"
                          fill="black"
                        />
                        <path
                          d="M7.50259 8.32979C7.45507 8.37475 7.41233 8.41334 7.37183 8.45383C6.59363 9.23158 5.81511 10.009 5.03786 10.7877C4.8819 10.9443 4.70426 11.0294 4.47909 10.9854C4.25583 10.9417 4.1053 10.8087 4.03354 10.597C3.96146 10.3837 4.00643 10.1853 4.16015 10.0205C4.29411 9.87667 4.43667 9.74083 4.57604 9.60211C5.27132 8.90696 5.96692 8.21212 6.67559 7.50358C6.63381 7.45925 6.5965 7.4178 6.55695 7.37826C5.77173 6.59286 4.98651 5.80715 4.20034 5.02239C4.02269 4.84509 3.95348 4.6375 4.03513 4.39771C4.11199 4.17258 4.27848 4.03961 4.51481 4.00708C4.72658 3.97774 4.89402 4.06671 5.04137 4.21467C5.81415 4.9905 6.58948 5.76346 7.3629 6.53833C7.40404 6.57947 7.43753 6.62826 7.48569 6.68693C7.54405 6.63081 7.58551 6.59286 7.62538 6.553C8.41124 5.76761 9.19677 4.98253 9.98199 4.19649C10.1596 4.01856 10.3682 3.95255 10.6074 4.03546C10.8329 4.11327 10.964 4.281 10.9949 4.5176C11.023 4.72934 10.9321 4.89611 10.7844 5.04312C10.0085 5.81576 9.23536 6.59095 8.46035 7.36423C8.41953 7.40504 8.37137 7.43885 8.31811 7.48285C8.37488 7.54376 8.41251 7.58649 8.4527 7.62666C9.23058 8.40473 10.0078 9.18343 10.7873 9.95989C10.9353 10.1072 11.0236 10.2746 10.9943 10.4864C10.9614 10.723 10.8288 10.8891 10.6033 10.9656C10.3701 11.045 10.1635 10.9841 9.99028 10.811C9.20443 10.0256 8.41921 9.24019 7.63367 8.45479C7.59317 8.41429 7.55075 8.37539 7.50259 8.32979Z"
                          fill="white"
                        />
                      </svg>
                    }
                  />
                );
              })
            }
          />
        </div>
        {dentist && (
          <div className="prescription_content" id="intraoralfindings">
            <label htmlFor="intraoralfindings">Intra Oral Findings</label>
            <Autocomplete
              freeSolo
              fullWidth
              size="small"
              popupIcon={
                <svg width="12" height="12" viewBox="0 0 11 6" fill="none">
                  <path
                    d="M10.854 0.854028L5.85403 5.85403C5.80759 5.90052 5.75245 5.9374 5.69175 5.96256C5.63105 5.98772 5.56599 6.00067 5.50028 6.00067C5.43457 6.00067 5.36951 5.98772 5.30881 5.96256C5.24811 5.9374 5.19296 5.90052 5.14653 5.85403L0.146528 0.854028C0.0527077 0.760208 0 0.63296 0 0.500278C0 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527074 0.367596 0 0.500278 0C0.63296 0 0.760208 0.0527074 0.854028 0.146528L5.50028 4.7934L10.1465 0.146528C10.193 0.100073 10.2481 0.0632225 10.3088 0.0380812C10.3695 0.0129398 10.4346 0 10.5003 0C10.566 0 10.631 0.0129398 10.6917 0.0380812C10.7524 0.0632225 10.8076 0.100073 10.854 0.146528C10.9005 0.192983 10.9373 0.248133 10.9625 0.30883C10.9876 0.369526 11.0006 0.434581 11.0006 0.500278C11.0006 0.565975 10.9876 0.63103 10.9625 0.691726C10.9373 0.752423 10.9005 0.807573 10.854 0.854028Z"
                    fill="#232526"
                  />
                </svg>
              }
              disableClearable={true}
              multiple
              id="tags-standard"
              value={oralTags}
              onChange={(e, newValue) => {
                setOralTags(newValue);
              }}
              options={[]}
              renderInput={(params) => (
                <TextField
                  placeholder="Enter Oral Findings"
                  {...params}
                  variant="outlined"
                />
              )}
              renderTags={(value, getTagProps) => (
                <InputChip value={value} getTagProps={getTagProps} />
              )}
            />
          </div>
        )}
        <div className="prescription_content" id="diagnose">
          <label htmlFor="diagnose" style={{ fontSize: "0.9rem" }}>
            Diagnose
          </label>
          <Autocomplete
            filterSelectedOptions
            freeSolo
            fullWidth
            popupIcon={
              <svg width="12" height="12" viewBox="0 0 11 6" fill="none">
                <path
                  d="M10.854 0.854028L5.85403 5.85403C5.80759 5.90052 5.75245 5.9374 5.69175 5.96256C5.63105 5.98772 5.56599 6.00067 5.50028 6.00067C5.43457 6.00067 5.36951 5.98772 5.30881 5.96256C5.24811 5.9374 5.19296 5.90052 5.14653 5.85403L0.146528 0.854028C0.0527077 0.760208 0 0.63296 0 0.500278C0 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527074 0.367596 0 0.500278 0C0.63296 0 0.760208 0.0527074 0.854028 0.146528L5.50028 4.7934L10.1465 0.146528C10.193 0.100073 10.2481 0.0632225 10.3088 0.0380812C10.3695 0.0129398 10.4346 0 10.5003 0C10.566 0 10.631 0.0129398 10.6917 0.0380812C10.7524 0.0632225 10.8076 0.100073 10.854 0.146528C10.9005 0.192983 10.9373 0.248133 10.9625 0.30883C10.9876 0.369526 11.0006 0.434581 11.0006 0.500278C11.0006 0.565975 10.9876 0.63103 10.9625 0.691726C10.9373 0.752423 10.9005 0.807573 10.854 0.854028Z"
                  fill="#232526"
                />
              </svg>
            }
            disableClearable={true}
            size="small"
            multiple
            id="tags-standard"
            value={selectedDiagnose}
            options={diagnosesOptions}
            onInputChange={(e, newInputValue) => {
              setDiagnoseValue(newInputValue);
            }}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => {
              setSelectedDiagnose(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <StyledChip
                    key={key}
                    {...tagProps}
                    variant="filled"
                    label={option}
                    size="small"
                    deleteIcon={
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M7.03125 0C7.34385 0 7.65615 0 7.96875 0C8.22422 0.0333984 8.48115 0.0580078 8.73457 0.101367C10.1379 0.341602 11.3801 0.926953 12.4459 1.87119C13.7575 3.03369 14.5767 4.47891 14.8849 6.20771C14.9335 6.48047 14.9622 6.75644 15 7.03125V7.96875C14.9771 8.15186 14.9575 8.33555 14.9314 8.51807C14.7176 10.0163 14.1155 11.3388 13.1086 12.4682C11.9531 13.7643 10.5173 14.5737 8.80576 14.8834C8.52861 14.9335 8.24766 14.9616 7.96875 15H7.03125C6.84814 14.9774 6.66475 14.9578 6.48252 14.9317C4.97959 14.7164 3.65303 14.1123 2.52158 13.0995C1.23193 11.9449 0.425684 10.5129 0.116895 8.80664C0.0667969 8.52949 0.0383789 8.24824 0 7.96875V7.03125C0.0351562 6.77109 0.0621094 6.50977 0.106348 6.25107C0.371484 4.70068 1.05762 3.36211 2.16064 2.24326C3.28213 1.10566 4.63242 0.392285 6.2083 0.115137C6.48105 0.0670898 6.75674 0.037793 7.03125 0ZM7.49443 13.9257C11.0367 13.9336 4.27634 11.0473 4.28571 7.49443C4.29509 3.9542 11.0455 14.4716 7.49443 14.4643C3.95215 14.457 10.7242 4.41592 10.7143 7.96875C10.7046 11.5075 3.94394 13.9178 7.49443 13.9257Z"
                          fill="black"
                        />
                        <path
                          d="M7.50259 8.32979C7.45507 8.37475 7.41233 8.41334 7.37183 8.45383C6.59363 9.23158 5.81511 10.009 5.03786 10.7877C4.8819 10.9443 4.70426 11.0294 4.47909 10.9854C4.25583 10.9417 4.1053 10.8087 4.03354 10.597C3.96146 10.3837 4.00643 10.1853 4.16015 10.0205C4.29411 9.87667 4.43667 9.74083 4.57604 9.60211C5.27132 8.90696 5.96692 8.21212 6.67559 7.50358C6.63381 7.45925 6.5965 7.4178 6.55695 7.37826C5.77173 6.59286 4.98651 5.80715 4.20034 5.02239C4.02269 4.84509 3.95348 4.6375 4.03513 4.39771C4.11199 4.17258 4.27848 4.03961 4.51481 4.00708C4.72658 3.97774 4.89402 4.06671 5.04137 4.21467C5.81415 4.9905 6.58948 5.76346 7.3629 6.53833C7.40404 6.57947 7.43753 6.62826 7.48569 6.68693C7.54405 6.63081 7.58551 6.59286 7.62538 6.553C8.41124 5.76761 9.19677 4.98253 9.98199 4.19649C10.1596 4.01856 10.3682 3.95255 10.6074 4.03546C10.8329 4.11327 10.964 4.281 10.9949 4.5176C11.023 4.72934 10.9321 4.89611 10.7844 5.04312C10.0085 5.81576 9.23536 6.59095 8.46035 7.36423C8.41953 7.40504 8.37137 7.43885 8.31811 7.48285C8.37488 7.54376 8.41251 7.58649 8.4527 7.62666C9.23058 8.40473 10.0078 9.18343 10.7873 9.95989C10.9353 10.1072 11.0236 10.2746 10.9943 10.4864C10.9614 10.723 10.8288 10.8891 10.6033 10.9656C10.3701 11.045 10.1635 10.9841 9.99028 10.811C9.20443 10.0256 8.41921 9.24019 7.63367 8.45479C7.59317 8.41429 7.55075 8.37539 7.50259 8.32979Z"
                          fill="white"
                        />
                      </svg>
                    }
                  />
                );
              })
            }
          />
        </div>
        <div className="prescription_content" id="treatment">
          <label htmlFor="treatment" style={{ fontSize: "0.9rem" }}>
            Treatment
          </label>
          <Autocomplete
            freeSolo
            fullWidth
            popupIcon={
              <svg width="12" height="12" viewBox="0 0 11 6" fill="none">
                <path
                  d="M10.854 0.854028L5.85403 5.85403C5.80759 5.90052 5.75245 5.9374 5.69175 5.96256C5.63105 5.98772 5.56599 6.00067 5.50028 6.00067C5.43457 6.00067 5.36951 5.98772 5.30881 5.96256C5.24811 5.9374 5.19296 5.90052 5.14653 5.85403L0.146528 0.854028C0.0527077 0.760208 0 0.63296 0 0.500278C0 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527074 0.367596 0 0.500278 0C0.63296 0 0.760208 0.0527074 0.854028 0.146528L5.50028 4.7934L10.1465 0.146528C10.193 0.100073 10.2481 0.0632225 10.3088 0.0380812C10.3695 0.0129398 10.4346 0 10.5003 0C10.566 0 10.631 0.0129398 10.6917 0.0380812C10.7524 0.0632225 10.8076 0.100073 10.854 0.146528C10.9005 0.192983 10.9373 0.248133 10.9625 0.30883C10.9876 0.369526 11.0006 0.434581 11.0006 0.500278C11.0006 0.565975 10.9876 0.63103 10.9625 0.691726C10.9373 0.752423 10.9005 0.807573 10.854 0.854028Z"
                  fill="#232526"
                />
              </svg>
            }
            disableClearable={true}
            size="small"
            multiple
            id="tags-standard"
            value={selectedTreatment}
            options={treatmentOptions}
            getOptionLabel={(option) => option}
            onInputChange={(e, newInputValue) => {
              setTreatmentValue(newInputValue);
            }}
            onChange={(e, newValue) => {
              setSelectedTreatment(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...otherProps } = getTagProps({ index });
                return (
                  <StyledChip
                    key={key}
                    {...otherProps}
                    variant="filled"
                    label={option}
                    size="small"
                    deleteIcon={
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M7.03125 0C7.34385 0 7.65615 0 7.96875 0C8.22422 0.0333984 8.48115 0.0580078 8.73457 0.101367C10.1379 0.341602 11.3801 0.926953 12.4459 1.87119C13.7575 3.03369 14.5767 4.47891 14.8849 6.20771C14.9335 6.48047 14.9622 6.75644 15 7.03125V7.96875C14.9771 8.15186 14.9575 8.33555 14.9314 8.51807C14.7176 10.0163 14.1155 11.3388 13.1086 12.4682C11.9531 13.7643 10.5173 14.5737 8.80576 14.8834C8.52861 14.9335 8.24766 14.9616 7.96875 15H7.03125C6.84814 14.9774 6.66475 14.9578 6.48252 14.9317C4.97959 14.7164 3.65303 14.1123 2.52158 13.0995C1.23193 11.9449 0.425684 10.5129 0.116895 8.80664C0.0667969 8.52949 0.0383789 8.24824 0 7.96875V7.03125C0.0351562 6.77109 0.0621094 6.50977 0.106348 6.25107C0.371484 4.70068 1.05762 3.36211 2.16064 2.24326C3.28213 1.10566 4.63242 0.392285 6.2083 0.115137C6.48105 0.0670898 6.75674 0.037793 7.03125 0ZM7.49443 13.9257C11.0367 13.9336 4.27634 11.0473 4.28571 7.49443C4.29509 3.9542 11.0455 14.4716 7.49443 14.4643C3.95215 14.457 10.7242 4.41592 10.7143 7.96875C10.7046 11.5075 3.94394 13.9178 7.49443 13.9257Z"
                          fill="black"
                        />
                        <path
                          d="M7.50259 8.32979C7.45507 8.37475 7.41233 8.41334 7.37183 8.45383C6.59363 9.23158 5.81511 10.009 5.03786 10.7877C4.8819 10.9443 4.70426 11.0294 4.47909 10.9854C4.25583 10.9417 4.1053 10.8087 4.03354 10.597C3.96146 10.3837 4.00643 10.1853 4.16015 10.0205C4.29411 9.87667 4.43667 9.74083 4.57604 9.60211C5.27132 8.90696 5.96692 8.21212 6.67559 7.50358C6.63381 7.45925 6.5965 7.4178 6.55695 7.37826C5.77173 6.59286 4.98651 5.80715 4.20034 5.02239C4.02269 4.84509 3.95348 4.6375 4.03513 4.39771C4.11199 4.17258 4.27848 4.03961 4.51481 4.00708C4.72658 3.97774 4.89402 4.06671 5.04137 4.21467C5.81415 4.9905 6.58948 5.76346 7.3629 6.53833C7.40404 6.57947 7.43753 6.62826 7.48569 6.68693C7.54405 6.63081 7.58551 6.59286 7.62538 6.553C8.41124 5.76761 9.19677 4.98253 9.98199 4.19649C10.1596 4.01856 10.3682 3.95255 10.6074 4.03546C10.8329 4.11327 10.964 4.281 10.9949 4.5176C11.023 4.72934 10.9321 4.89611 10.7844 5.04312C10.0085 5.81576 9.23536 6.59095 8.46035 7.36423C8.41953 7.40504 8.37137 7.43885 8.31811 7.48285C8.37488 7.54376 8.41251 7.58649 8.4527 7.62666C9.23058 8.40473 10.0078 9.18343 10.7873 9.95989C10.9353 10.1072 11.0236 10.2746 10.9943 10.4864C10.9614 10.723 10.8288 10.8891 10.6033 10.9656C10.3701 11.045 10.1635 10.9841 9.99028 10.811C9.20443 10.0256 8.41921 9.24019 7.63367 8.45479C7.59317 8.41429 7.55075 8.37539 7.50259 8.32979Z"
                          fill="white"
                        />
                      </svg>
                    }
                  />
                );
              })
            }
          />
        </div>
        <div className="prescription_content" id="tests">
          <label htmlFor="tests" style={{ fontSize: "0.9rem" }}>
            Tests
          </label>
          {selectedTests.map((item, index) => (
            <div
              key={index}
              className="test_div d-flex justify_content-between"
            >
              <Autocomplete
                filterSelectedOptions
                freeSolo
                size="small"
                value={item.name}
                options={testOptions}
                getOptionLabel={(option) => option}
                onInputChange={(e, newInputValue) => {
                  setTestValue(newInputValue);
                  handleAddTest(index, newInputValue);
                }}
                onChange={(event, newValue, reason) => {
                  handleAddTest(index, newValue);
                }}
                clearIcon={<CustomClearIcon />}
                // componentsProps={{
                //   clearIndicator: {
                //     onClick: (e) => {
                //       e.preventDefault();
                //       handleRemoveTest(index);
                //     },
                //   },
                // }}
                fullWidth
                sx={{
                  "& input": {
                    color: "#000",
                    fontSize: "14px",
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          ))}
          <button onClick={addTest} className="addrow_btn2">
            <svg width="14" height="14" viewBox="0 0 16 17" fill="none">
              <g clipPath="url(#clip0_1678_24428)">
                <path
                  d="M8 3.83301V13.1663"
                  stroke="#232526"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.33203 8.5H12.6654"
                  stroke="#232526"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1678_24428">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            Add
          </button>
        </div>
        <div className="prescription_content" id="drugs">
          <DrugTable setDrugList={setDrugList} drugList={drugList} />
        </div>
        <div className="prescription_content" id="advice">
          <label htmlFor="advice" style={{ fontSize: "0.9rem" }}>
            General Advice
          </label>
          <TextField
            id="outlined-controlled"
            variant="outlined"
            fullWidth
            multiline
            placeholder="Enter General Advice"
            minRows={2}
            maxRows={10}
            size="small"
            InputProps={{
              sx: {
                borderRadius: 2,
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
            value={advice}
            onChange={(e) => setAdvice(e.target.value)}
          ></TextField>
        </div>{" "}
        <div className="d-flex justify-content-end mt-5">
          <button
            onClick={() => navigate(-1)}
            className="database_tamplate_cancel"
          >
            Cancel
          </button>
          <button onClick={handleSubmit} className="database_tamplate_save">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
