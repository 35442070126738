import api from "../utils/Api";
export async function getDoctorSpecialization() {
  try {
    const response = await api.get(`/specialization`);
    if (response) return response;
    else throw new Error("Could not get specialization");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getAllTemplate() {
  try {
    const response = await api.get(`/admin/templates/`);
    if (response) return response;
    else throw new Error("Could not get templates");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getSpeacialityTemplates(id) {
  try {
    const response = await api.get(`/admin/templates/${id}`);
    if (response) return response;
    else throw new Error("Could not get templates");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getSearchedDignoses(text) {
  try {
    const response = await api.get(
      `/prescription/diagnosis?search=${text}&max=20?onlysct=true`
    );
    const length = 20 - response.data.data.length;
    const res = await getSnomedDiagnosticData(text, length);
    response.data.data = [...response.data.data, ...res];
    if (response) return response;
    else throw new Error("Could not get diagnoses");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
const getSnomedDiagnosticData = async (search, max) => {
  try {
    console.log(max, "max");
    const count = max || 10;

    const SNOMED_URL = `https://snowstorm-fhir.snomedtools.org/fhir/ValueSet/$expand?url=http%3A%2F%2Fsnomed.info%2Fsct%3Ffhir_vs%3Decl%2F%3C%20404684003&_format=json&count=${count}&filter=${
      search ? search : ""
    }`;

    const response = await fetch(SNOMED_URL, {
      headers: { "Accept-Language": "en-US" },
    });

    const data = await response.json();
    let diagnoseList = data?.expansion?.contains;

    diagnoseList = diagnoseList?.map((item) => {
      return { name: item.display };
    });
    return diagnoseList;
  } catch (error) {
    throw error;
  }
};

export async function getSearchedTreatments(text) {
  try {
    const response = await api.get(
      `/prescription/treatment?search=${text}&max=20`
    );
    if (response) return response;
    else throw new Error("Could not get treatment");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getSearchedTests(text) {
  try {
    const response = await api.get(
      `/admin/templates/tests?search=${text}&max=20`
    );
    if (response) return response;
    else throw new Error("Could not get treatment");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getSearchedDrugs(text) {
  try {
    const response = await api.get(
      `/admin/templates/drugs?search=${text}&max=20`
    );
    if (response) return response;
    else throw new Error("Could not get treatment");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getallDrugType() {
  try {
    const response = await api.get("/drug_type");
    if (response) return response;
    else throw new Error("Could not get drugType");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getDoses() {
  try {
    const response = await api.get(`/doses_type`);
    if (response) return response;
    else throw new Error("Could not get doses");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function getDatabaseReminders() {
  try {
    const response = await api.get("/reminder");
    if (response) return response;
    else throw new Error("Could not get reminders");
  } catch (err) {
    // console.log(err);
    return err.response;
  }
}
export async function getTemplateByID(id) {
  try {
    const response = await api.get(`/prescription/template/${id}`);
    if (response) return response;
    else throw new Error("Could not get template id");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function createTemplate(template) {
  try {
    const response = await api.post(`/admin/templates`, template);
    if (response) return response;
    else throw new Error("Could not create template");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function updateTemplate(template, id) {
  try {
    const response = await api.patch(`/admin/templates/${id}`, template);
    if (response) return response;
    else throw new Error("Could not patch template");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
export async function deleteTemplate(id) {
  try {
    const response = await api.delete(`/admin/templates/${id}`);
    if (response) return response;
    else throw new Error("Could not delete template");
  } catch (err) {
    console.log(err);
    return err.response;
  }
}
