import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const CustomDatePicker = ({ value, onDateChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          onChange={onDateChange}
          value={moment(new Date(value).toDateString())}
          slotProps={{ textField: { size: "small", fullWidth: true } }}
          defaultValue={moment(new Date(value).toDateString())}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
