import {
    Backdrop,
    CircularProgress,
    Grid,
    Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import { useLocation } from "react-router-dom";
import AvailabilityModel from "./availabilityModel";
import AvailabilityListModel from "./availabilityListModel";

const HospitalDoctors = () => {
    const location = useLocation();
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [doctorData, setDoctorData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openAvailabilityModel, setOpenAvailabilityModel] = useState(false);

    const [data, setData] = useState({});
    const [isEditDoctor, setIsEditDoctor] = useState(false)
    const [selectedDoctorId, setSelectedDoctorId] = useState(0);
    const tableHeader = [
        "Name",
        "Phone",
        "Email",
        "Type",
        "Status",
    ];
    const keysName = [
        "name",
        "phone",
        "email",
        "role",
        "doctorStatus",
    ];

    const hospitalWiseDoctorList = (e) => {
        try {
            setBackDropOpen(true);
            const payload = {
                ...(e &&
                    e.target.value && {
                    search: e.target.value,
                }),
                limit: rowsPerPage,
                page: pageNumber + 1,
                hospitalId: location.state.hospitalId
            };

            api
                .post("/admin/get-doctor-list-by-hospital", payload)
                .then((response) => {
                    const list = response?.data?.data?.doctorList?.map(x => {
                        return {
                            ...x,
                            role: x.role.type
                        }
                    })
                    setDoctorData(list || []);
                    setCount(response?.data?.data?.totalCount);
                    setBackDropOpen(false);
                })
                .catch((err) => {
                    setBackDropOpen(false);
                    if (err?.response?.data?.message) {
                        toast.error(`Error :${err?.response?.data?.message}`);
                    } else {
                        toast.error("Hospitals `Doctor` Not Found");
                    }
                });
        } catch (error) {
            setBackDropOpen(false);
            toast.error("Doctor list error");
        }
    };

    const handleChangePage = (event, newPage) => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPageNumber(0);
    };

    const handleModal = (isEdit, e = {}) => {
        setData(e);
        setIsEditDoctor(isEdit)
        setOpenModal(true);
    };

    const handleAvailabilityModal = (doctorId) => {
        setSelectedDoctorId(doctorId)
        setOpenAvailabilityModel(true);
    };

    const handleModalClose = (buttonText = "save") => {
        setData({});
        if (buttonText !== "close") {
            hospitalWiseDoctorList();
        }
        setOpenModal(false);
    };

    const handleAvalabilityModalClose = (buttonText = "save") => {
        if (buttonText !== "close") {
            // hospitalWiseDoctorList();
        }
        setOpenAvailabilityModel(false);
    };

    useEffect(() => {
        hospitalWiseDoctorList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, pageNumber]);
    return (
        <>
            <Grid container alignItems={"center"}>
                <Grid item sm={12} md={12} lg={12} justifyContent="center" padding={2}>
                    <Paper sx={{ p: 2 }}>
                        <Backdrop
                            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={backDropOpen}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Paper>
                    <Listtable
                        tableheader={tableHeader}
                        tablebody={doctorData}
                        rowsPerPage={rowsPerPage}
                        page={pageNumber}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        totalCount={count}
                        dataKeyName={keysName}
                        editHandle={(e) => handleModal(true, e)}
                        handleAvailabilityModal={handleAvailabilityModal}
                        tag="hospitalDoctors"
                    />
                </Grid>
            </Grid>

            {openModal && (
                <AvailabilityModel
                    open={openModal}
                    onClose={handleModalClose}
                    doctorData={data}
                    isEditDoctor={isEditDoctor}
                    hospitalId={location?.state?.hospitalId}
                    isEdit={false}

                />
            )}

            {openAvailabilityModel && (
                <AvailabilityListModel
                    open={openAvailabilityModel}
                    onClose={handleAvalabilityModalClose}
                    hospitalId={location?.state?.hospitalId}
                    doctorId={selectedDoctorId}

                />
            )}
        </>
    );
};

export default HospitalDoctors;
