import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  colors,
  Grid,
  Icon,
  InputAdornment,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import ModalScreen from "../Modal";
import SearchIcon from "@mui/icons-material/Search";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { CSVLink } from "react-csv";
import { useOutletContext } from "react-router-dom";

const DrugList = () => {
  const [count, setCount] = useState(0);
  const [activeTab, setActiveTab] = useState("drug");

  const [pageNumber, setPageNumber] = useState(0);
  const [drugsData, setDrugsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [list, setList] = useState([]);
  const { searchText } = useOutletContext();
  const tableHeader = [
    "Company Name",
    "Drug Name",
    "Generic Name",
    "Drug Type",
    "Doctor Speciality",
  ];
  const tableHeader2 = ["Name"];
  const keysName = [
    "company",
    "drugName",
    "genericName",
    "drugType",
    "speciality",
  ];

  const keysName2 = ["name"];

  const btnStyle = {
    width: "max-content",
    padding: "0.5rem 2rem",
    color: "#194AF5",
    bgcolor: "#E6EBFF",
    boxShadow: "none",
    borderRadius: "8px",
    ":hover": {
      bgcolor: "#E6EBFF",
      color: "#194AF5",
      boxShadow: "none",
    },
  };
  useEffect(() => {
    const data = list?.filter((x) =>
      activeTab === "test"
        ? x?.name?.toLowerCase()?.includes(searchText.toLowerCase())
        : x?.drugName?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    setDrugsData(data);
  }, [searchText, list, activeTab]);
  const DrugListData = (e) => {
    try {
      setBackDropOpen(true);
      const PayLoadDoctorList = {
        ...(e &&
          e.target.value && {
            text: e.target.value,
          }),
        limit: rowsPerPage,
        page: pageNumber + 1,
      };
      const url = e
        ? `/${activeTab}?text=${e?.target?.value}`
        : `/${activeTab}`;

      api
        .get(url, PayLoadDoctorList)
        .then((response) => {
          console.log("response drug", response);
          const data =
            (activeTab === "drug" && response?.data?.data?.Drugs.reverse()) ||
            (activeTab === "test" && response?.data?.data?.Tests);
          setDrugsData(data);

          setList(data);
          setCount(response?.data?.data?.totalCount);
          setBackDropOpen(false);
        })
        .catch((err) => {
          setBackDropOpen(false);
          if (err?.response?.data?.message) {
            toast.error(`Error :${err?.response?.data?.message}`);
          } else {
            toast.error("Drugs Not Found");
          }
        });
    } catch (error) {
      setBackDropOpen(false);
      toast.error("Drugs list error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPageNumber(0);
  };

  const handleModal = (e) => {
    setData(e);
    setOpenModal(true);
  };
  const handleChange = function (e) {
    try {
      if (e?.target?.files.length > 0) {
        setIsUploading(true);
        let fileData = e?.target?.files[0];
        console.log("File Data", fileData);
        var csvUploadBody = new FormData();
        csvUploadBody.append("drugs", fileData);

        api
          .post("/admin/add-drugs", csvUploadBody, {
            "Content-Type": "multipart/form-data",
          })
          .then(() => {
            toast.success(`Drugs uploaded successfully !`);
            setRowsPerPage(5);
            setPageNumber(0);
            setIsUploading(false);
            DrugListData();
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(`Error : ${err?.response?.data?.message}`);
            } else {
              toast.error(`Drugs upload failed !`);
            }
            setIsUploading(false);
          });
        e.target.value = null;
      } else {
        e.target.value = null;
        toast.error(`Selected a file !`);
      }
    } catch (err) {
      console.log("Error", err);
      setIsUploading(false);
      toast.error(`Drugs upload failed !`);
    }
  };

  const handleUploadButtonClick = () => {
    document.getElementById("input-file-upload").click();
  };

  const handleModalClose = (buttonText = "save") => {
    setData({});
    if (buttonText !== "close") {
      DrugListData();
    }
    setOpenModal(false);
  };

  useEffect(() => {
    DrugListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, pageNumber]);

  useEffect(() => {
    DrugListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={12} md={12} lg={12} padding={2}>
          <Grid
            container
            spacing={1}
            sx={{
              padding: "1rem 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "auto",
              }}
            >
              <Box
                onClick={(e) => {
                  setActiveTab("drug");
                }}
                sx={{
                  borderBottom:
                    activeTab === "drug"
                      ? "2px solid #194AF5"
                      : "2px solid transparent",
                  color: activeTab === "drug" ? "#194AF5" : "#7D8DC4",
                  width: "max-content",
                  padding: "0.5rem 1rem",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Drugs
              </Box>
              <Box
                onClick={(e) => {
                  setActiveTab("test");
                }}
                sx={{
                  borderBottom:
                    activeTab !== "drug"
                      ? "2px solid #194AF5"
                      : "2px solid transparent",
                  color: activeTab !== "drug" ? "#194AF5" : "#7D8DC4",
                  width: "max-content",
                  padding: "0.5rem 1rem",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Test
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              {/* <CSVLink
                // data={csvSampleData}
                filename={"drugSample.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    ...btnStyle,
                    // display: "flex",
                    // alignItems: "center",
                    // gap: "0.5rem",
                  }}
                >
                  {isUploading ? (
                    <CircularProgress sx={{ color: "white" }} size="1.5rem" />
                  ) : (
                    <>
                      <svg
                        width={16}
                        height={17}
                        style={{ marginRight: "0.5rem" }}
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_4226_14049)">
                          <path
                            d="M6.58614 12.5813C6.77187 12.7672 6.9924 12.9146 7.23514 13.0152C7.47787 13.1158 7.73805 13.1676 8.0008 13.1676C8.26355 13.1676 8.52373 13.1158 8.76646 13.0152C9.0092 12.9146 9.22973 12.7672 9.41547 12.5813L11.5561 10.4407C11.6709 10.3137 11.7325 10.1475 11.7281 9.97644C11.7237 9.80536 11.6537 9.64253 11.5325 9.52165C11.4114 9.40077 11.2484 9.3311 11.0773 9.32707C10.9062 9.32304 10.7402 9.38496 10.6135 9.5L8.6628 11.4513L8.66747 1.16667C8.66747 0.989856 8.59723 0.820286 8.47221 0.695262C8.34718 0.570238 8.17761 0.5 8.0008 0.5C7.82399 0.5 7.65442 0.570238 7.5294 0.695262C7.40437 0.820286 7.33413 0.989856 7.33413 1.16667L7.32814 11.4387L5.38814 9.5C5.26304 9.375 5.09341 9.3048 4.91657 9.30486C4.73972 9.30493 4.57014 9.37524 4.44514 9.50033C4.32013 9.62543 4.24994 9.79506 4.25 9.9719C4.25006 10.1487 4.32037 10.3183 4.44547 10.4433L6.58614 12.5813Z"
                            fill="#194AF5"
                          />
                          <path
                            d="M15.3333 11.167C15.1565 11.167 14.987 11.2372 14.8619 11.3623C14.7369 11.4873 14.6667 11.6568 14.6667 11.8337V14.5003C14.6667 14.6771 14.5964 14.8467 14.4714 14.9717C14.3464 15.0968 14.1768 15.167 14 15.167H2C1.82319 15.167 1.65362 15.0968 1.5286 14.9717C1.40357 14.8467 1.33333 14.6771 1.33333 14.5003V11.8337C1.33333 11.6568 1.2631 11.4873 1.13807 11.3623C1.01305 11.2372 0.843478 11.167 0.666667 11.167C0.489856 11.167 0.320286 11.2372 0.195262 11.3623C0.0702379 11.4873 0 11.6568 0 11.8337L0 14.5003C0 15.0308 0.210714 15.5395 0.585786 15.9145C0.960859 16.2896 1.46957 16.5003 2 16.5003H14C14.5304 16.5003 15.0391 16.2896 15.4142 15.9145C15.7893 15.5395 16 15.0308 16 14.5003V11.8337C16 11.6568 15.9298 11.4873 15.8047 11.3623C15.6797 11.2372 15.5101 11.167 15.3333 11.167Z"
                            fill="#194AF5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4226_14049">
                            <rect
                              width={16}
                              height={16}
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      CSV template
                    </>
                  )}
                </Button>
              </CSVLink> */}
              {/* <Button
                sx={btnStyle}
                variant="contained"
                onClick={handleUploadButtonClick}
                fullWidth
              >
                {isUploading ? (
                  <CircularProgress sx={{ color: "white" }} size="1.5rem" />
                ) : (
                  <>Upload via CSV</>
                )}
                <input
                  type="file"
                  accept="text/csv"
                  id="input-file-upload"
                  multiple={false}
                  hidden
                  onChange={handleChange}
                />
              </Button> */}
              <Button
                sx={{
                  ...btnStyle,
                  bgcolor: "#194AF5",
                  color: "white",
                  ":hover": {},
                }}
                variant="contained"
                fullWidth
                onClick={() => handleModal({})}
              >
                {activeTab === "drug" ? "Add Drug" : "Add Test"}
              </Button>
            </Box>
          </Grid>

          <Listtable
            tableheader={activeTab === "drug" ? tableHeader : tableHeader2}
            tablebody={drugsData}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={count}
            dataKeyName={activeTab === "drug" ? keysName : keysName2}
            editHandle={handleModal}
            tag="drugs"
          />
        </Grid>
      </Grid>
      {openModal && (
        <ModalScreen
          open={openModal}
          onClose={handleModalClose}
          data={data}
          type={activeTab}
        />
      )}
    </>
  );
};

export default DrugList;
