import {
    Tab, Box, Modal, Stack, Button, Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../utils/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AvailabilityModel from "./availabilityModel";

const AvailabilityListModel = ({ open, onClose, hospitalId, doctorId }) => {
    const { t } = useTranslation();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    const [tabValue, setTabValue] = useState('1');
    const [availabilitiesList, setAvailabilitiesList] = useState([]);
    const [unavailabilitiesList, setUnavailabilitiesList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [currentAvailability, setCurrentAvailability] = useState(false);
    const [currentUnavailability, setCurrentUnavailability] = useState(false);
    const [formName, setFormName] = useState("availability");




    useEffect(() => {
        getAvailabilityByHospitalDoctor();
        getUnavailabilityByHospitalDoctor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getAvailabilityByHospitalDoctor = (e) => {
        let payload = {
            clinicId: hospitalId,
            doctorId
        }

        api.post(`/reg/get-doctor-hospital-slot`, payload)
            .then((res) => {
                let availabilities = res?.data?.availabilities?.map((x, index) => {
                    x.id = (index + 1);
                    return x;
                }) || []
                availabilities?.map((x, index) => {
                    x.id = (index + 1);
                    return x;
                });
                setAvailabilitiesList(availabilities || [])
                // toast.success(res && res?.message);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error);
            });

    }


    const getUnavailabilityByHospitalDoctor = (e) => {
        let payload = {
            clinicId: hospitalId,
            doctorId
        }

        api.post(`/reg/get-doctor-hospital-unavailabilities`, payload)
            .then((res) => {
                let unavailabilities = res?.data?.unavailabilities?.map((x, index) => {
                    x.id = (index + 1);
                    return x;
                }) || []
                setUnavailabilitiesList(unavailabilities || [])
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error);
            });

    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleModal = (data, name) => {
        if (name === "availability") {
            setCurrentAvailability(data)
        } else {
            setCurrentUnavailability(data)
        }
        setFormName(name)
        setOpenModal(true);
    };

    const handleModalClose = (buttonText = "save") => {
        if (buttonText !== "close") {
            getAvailabilityByHospitalDoctor();
            getUnavailabilityByHospitalDoctor();
        }
        setOpenModal(false);
    };

    const handleDeleteAvailability = (availabilityData) => {
        let payload = {
            days: availabilityData.days,
            startTime: availabilityData.startTime,
            endTime: availabilityData.endTime,
            slotId: availabilityData._id,
            maxAppointments: availabilityData.maxAppointments,
            clinicId: hospitalId,
            doctorId,
            softDelete: true
        }

        api.post(`/reg/add-doctor-hospital-slot`, payload)
            .then((res) => {
                onClose();
                toast.success(res && res?.message);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error);
            });

    }

    const handleDeleteUnavailability = (unavailability) => {

        let payload = {
            clinicId: hospitalId,
            doctorId,
            startDate: unavailability.startDate,
            endDate: unavailability._id,
            slotId: unavailability._id,
            softDelete: true
        }

        api.post(`/reg/add-doctor-hospital-unavailabilities`, payload)
            .then((res) => {
                onClose();
                toast.success(res && res?.message);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.error);
            });

    }


    const availabilityColumn = [

        {
            field: 'id', headerName: 'Sr. No.', width: 60,
            headerClassName: 'super-app-theme--header', disableColumnMenu: true, sortable: false
        },
        {
            field: 'startTime',
            headerName: 'Start Time',
            width: 120,
            editable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true, sortable: false,
            renderCell: (params) => (
                <Typography variant="subtitle2">
                    {moment(params.row.startTime, 'HH mm').format("hh:mm A")}
                </Typography>
            ),


        },
        {
            field: 'endTime',
            headerName: 'end Time',
            width: 120,
            editable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true, sortable: false,
            renderCell: (params) => (
                <Typography variant="subtitle2">
                    {moment(params.row.endTime, 'HH mm').format("hh:mm A")}
                </Typography>
            ),
        },
        {
            field: 'maxAppointments',
            headerName: 'Max Appointments',
            width: 120,
            editable: false,
            align: 'center',
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true, sortable: false

        },
        {
            field: 'days',
            headerName: 'Days',
            width: 420,
            editable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true,
            width: 110,
            sortable: false,
            disableClickEventBubbling: true,

            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    handleModal(currentRow, 'availability');
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="warning" size="small" onClick={onClick}>Edit</Button>
                    </Stack>
                );
            },
        },
        {

            field: 'delete',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true,
            width: 110,
            sortable: false,
            disableClickEventBubbling: true,

            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    handleDeleteAvailability(currentRow);
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="warning" size="small" onClick={onClick}>Delete</Button>
                    </Stack>
                );
            },
        }
    ];

    const unavailabilityColumn = [

        {
            field: 'id', headerName: 'Sr. No.',
            width: 90,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true, sortable: false
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 365,
            editable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true, sortable: false,
            renderCell: (params) => (
                <Typography variant="subtitle2">
                    {moment(params.row.startDate).format("LLLL")}
                </Typography>
            ),
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 365,
            editable: false,
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true, sortable: false,
            renderCell: (params) => (
                <Typography variant="subtitle2">
                    {moment(params.row.endDate).format("LLLL")}
                </Typography>
            ),
        },

        {
            field: 'action',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true, sortable: false,
            width: 110,
            disableClickEventBubbling: true,

            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    handleModal(currentRow, 'unavailability');
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="warning" size="small" onClick={onClick}>Edit</Button>
                    </Stack>
                );
            },
        },
        {
            field: 'delete',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true,
            width: 110,
            sortable: false,
            disableClickEventBubbling: true,

            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    handleDeleteUnavailability(currentRow);
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="warning" size="small" onClick={onClick}>Delete</Button>
                    </Stack>
                );
            },
        }

    ];
    return (
        <div>
            <Modal
                open={open}
                onClose={() => onClose('close')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label="AVAILABILITY" value="1" />
                                <Tab label="UNAVAILABILITY" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <DataGrid
                                slots={{ hideFooter: true }}
                                sx={{
                                    boxShadow: 2,
                                    '& .super-app-theme--header': {
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                    },
                                    '& .MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                }}
                                rows={availabilitiesList}
                                columns={availabilityColumn}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 7,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                hideFooterPagination
                            />
                        </TabPanel>
                        <TabPanel value="2"> <DataGrid
                            sx={{
                                boxShadow: 2,
                                '& .super-app-theme--header': {
                                    backgroundColor: 'primary.main',
                                    color: 'primary.contrastText',
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                            }}
                            rows={unavailabilitiesList}
                            columns={unavailabilityColumn}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 7,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            hideFooterPagination
                        /></TabPanel>
                    </TabContext>


                </Box>
            </Modal >

            {openModal && (
                <AvailabilityModel
                    open={openModal}
                    onClose={handleModalClose}
                    hospitalId={hospitalId}
                    availabilityData={currentAvailability}
                    unavailabilityData={currentUnavailability}
                    isEdit={true}
                    editFormName={formName}
                />
            )}
        </div >
    );
};

export default AvailabilityListModel;
