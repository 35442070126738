
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./Table.css";
import { useNavigate } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";

const Listtable = ({
  tableheader,
  tablebody = [],
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  totalCount,
  dataKeyName,
  editHandle,
  tag,
  handleAvailabilityModal,
}) => {
  const navigate = useNavigate();

  const convertDate = (dateData) => {
    const date = new Date(dateData);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <TableContainer
        className="container"
        component={Paper}
        sx={{ padding: "1rem", boxShadow: "none" }}
      >
        <Table
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 1.5rem",
            borderCollapse: "separate",
            ".MuiTableCell-root": {
              borderBottom: "none",
            },
            tr: {
              border: "1px solid #DDD",
            },
            ".MuiTableHead-root": {
              borderRadius: "30px",
            },
          }}
        >
          <TableHead
            sx={{
              bgcolor: "#F9F9F9",
              borderRadius: "5px !important",
              borderBottom: "none",
            }}
          >
            <TableRow
              sx={{
                borderBottom: "none",
                outline: "none",
                borderRadius: "5px !important",
              }}
            >
              {tableheader?.map((thead, index) => (
                <TableCell
                  key={index}
                  style={{
                    fontWeight: "normal",
                    padding: "0.5rem 0rem ",
                    color: "#727272",
                    border: "none",
                    paddingLeft:
                      thead === "Name" ||
                      thead === "Specialization" ||
                      thead === "Drug Name" ||
                      thead === "Education" ||
                      thead === "Association"
                        ? "1rem"
                        : "0",
                  }}
                >
                  {thead}
                </TableCell>
              ))}
              {tag === "Specialization" || tag === "patients" || tag==="Association" ? (
                <TableCell
                  style={{
                    fontWeight: "normal",
                    padding: "0.5rem 0rem ",
                    color: "#727272",
                    border: "none",
                  }}
                ></TableCell>
              ) : (
                <TableCell
                  style={{
                    fontWeight: "normal",
                    padding: "0.5rem 0rem ",
                    color: "#727272",
                    border: "none",
                  }}
                >
                  Action
                </TableCell>
              )}
              {tag === "hospitals" && (
                <TableCell
                  style={{
                    fontWeight: "normal",
                    padding: "0.5rem 0rem ",
                    color: "#727272",
                  }}
                >
                  Doctors
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ border: "none" }}>
            {tablebody?.length === 0 && (
              <TableRow
                hover
                role="checkbox"
                key="data"
                sx={{ border: "1px solid #DDDDDD" }}
              >
                <TableCell
                  sx={{
                    padding: "0",
                  }}
                  colSpan={tableheader?.length}
                  align={"center"}
                >
                  <h2>No Data Available</h2>
                </TableCell>
              </TableRow>
            )}
            {tablebody?.length > 0 &&
              tablebody.map((tbody, index) => {
                return (
                  <TableRow
                    key={index}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      border: " 1px solid #DDDDDD",
                      borderRadius: "5px",
                      outline: "none",
                    }}
                  >
                    {dataKeyName.map((value, index) =>
                      value === "doctorStatus" ? (
                        <TableCell
                          key={index}
                          sx={{
                            padding: "0",
                            border: "none",
                          }}
                          style={{
                            color:
                              tbody[value] === "ACTIVE" ? "success" : "warning",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor:
                                (tbody?.subscription?.plan?.name ===
                                  "Standard Plan" &&
                                  "#EFFEED") ||
                                (tbody?.subscription?.plan?.name ===
                                  "Free Trial" &&
                                  "#EDF2FE") ||
                                (Object.keys(tbody?.subscription).length ===
                                  0 &&
                                  "#EDF2FE"),
                              padding: "0.3rem 1rem",
                              borderRadius: "25px",
                              width: "max-content",
                              color:
                                (tbody?.subscription?.plan?.name ===
                                  "Standard Plan" &&
                                  "#399F08") ||
                                (tbody?.subscription?.plan?.name ===
                                  "Free Trial" &&
                                  "#4976F4") ||
                                (Object.keys(tbody?.subscription).length ===
                                  0 &&
                                  "#4976F4"),
                              fontWeight: "600",
                              margin: "auto 0",
                              fontSize: "14px",
                            }}
                          >
                            {tbody?.subscription?.plan?.name ===
                              "Standard Plan" && "Standard"}
                            {tbody?.subscription?.plan?.name === "Free Trial" &&
                              "Free Trial"}
                            {Object.keys(tbody?.subscription).length === 0 &&
                              "Free Trial"}
                          </Box>
                        </TableCell>
                      ) : value === "createdAt" ? (
                        <TableCell
                          sx={{
                            padding: "0",
                            border: "none",
                          }}
                          key={index}
                        >
                          {convertDate(tbody[value]) || ""}
                        </TableCell>
                      ) : value === "drugType" || value === "speciality" ? (
                        <TableCell
                          sx={{
                            padding: "0",
                            border: "none",
                          }}
                          key={index}
                        >
                          {tbody[value]?.name || value}
                        </TableCell>
                      ) : value === "name" || value === "docCount" ? (
                        <TableCell
                          sx={{
                            padding: "0",
                            border: "none",
                            paddingLeft:
                              (value === "name" && "1rem") ||
                              (value === "Specialization" && "2rem") ||
                              (value === "Association" && "2rem"),
                          }}
                          key={index}
                        >
                          {tbody[value] === 0 ? tbody[value] : tbody[value]}
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{
                            padding: "0",
                            border: "none",
                            paddingLeft: value === "drugName" && "1rem",
                            textTransform:
                              (value === "genericName" && "uppercase") ||
                              (value === "drugName" && "uppercase"),
                          }}
                          key={index}
                        >
                          {tbody[value] || ""}
                        </TableCell>
                      )
                    )}
                    {tag === "doctorsrequest" && (
                      <TableCell
                        sx={{
                          padding: "0",
                          border: "none",
                        }}
                      >
                        <IconButton onClick={() => editHandle(tbody)}>
                          <DoneIcon color="primary" fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                    {tag !== "patients" && tag !== "doctorsrequest" && (
                      <TableCell
                        sx={{
                          padding: "0",
                          border: "none",
                        }}
                      >
                        <IconButton onClick={() => editHandle(tbody)}>
                          <svg
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_4184_12410)">
                              <path
                                d="M9 20.2501H4.5C4.30109 20.2501 4.11032 20.1711 3.96967 20.0305C3.82902 19.8898 3.75 19.699 3.75 19.5001V15.3104C3.75009 15.1118 3.82899 14.9213 3.96938 14.7807L15.5306 3.2195C15.6713 3.07895 15.862 3 16.0608 3C16.2596 3 16.4503 3.07895 16.5909 3.2195L20.7806 7.40637C20.9212 7.54701 21.0001 7.7377 21.0001 7.93653C21.0001 8.13535 20.9212 8.32605 20.7806 8.46668L9 20.2501Z"
                                stroke="black"
                                strokeWidth="0.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.25 20.25H9"
                                stroke="black"
                                strokeWidth="0.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12.75 6L18 11.25"
                                stroke="black"
                                strokeWidth="0.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4184_12410">
                                <rect width={24} height={24} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </IconButton>
                      </TableCell>
                    )}
                    {tag === "hospitals" && (
                      <TableCell
                        sx={{
                          padding: "0",
                          border: "none",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            navigate("/medicalFaculty/doctors", {
                              state: { hospitalId: tbody["_id"] },
                            })
                          }
                        >
                          <svg
                            width={26}
                            height={26}
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_4345_13589)">
                              <path
                                d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 13H28"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 19H28"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11 13V25"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4345_13589">
                                <rect width={32} height={32} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </IconButton>
                      </TableCell>
                    )}
                    {tag === "hospitalDoctors" && (
                      <TableCell
                        sx={{
                          padding: "0",
                          border: "none",
                        }}
                      >
                        <IconButton
                          onClick={() => handleAvailabilityModal(tbody._id)}
                        >
                          <ListAltIcon color="primary" fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </>
  );
};

export default Listtable;
