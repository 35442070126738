import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import "./Dashboard.css";
import DashboardNav from "../DashboardNav/DashboardNav";
const Dashboard = () => {
  const [searchText, setSearchText] = useState("");
  const contextValues = {
    searchText,
  };
  return (
    <div>
      <Navbar />
      <div className="dashboard_outlet">
        <div className="gradient_dashboard_nav">
          <svg width={433} height={519} viewBox="0 0 433 519" fill="none">
            <path
              d="M432.7 518.479C372.7 515.004 312.6 511.53 267.1 478.937C221.7 446.465 190.7 384.756 153.4 334.669C116 284.582 72.2 245.999 45.6 192.078C19 138.277 9.5 69.1386 0 0H432.7V518.479Z"
              fill="url(#paint0_linear_54_2223)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_54_2223"
                x1="456.5"
                y1="-209.093"
                x2="-218.367"
                y2="34.614"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.15795" stopColor="#EEF7FF" />
                <stop offset="0.729167" stopColor="#EEF7FF" stopOpacity={0} />
                <stop offset={1} stopColor="#EEF7FF" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="content_wrapper">
          <DashboardNav setSearchText={setSearchText} />
          <Outlet context={contextValues} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
